import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



let InputErrorComponent = ({ msg }) => {
    return <>
        {msg ?
            <div className={`flex flex-row items-center ${msg == "success" ? "text-green-400" : "text-red-400"} gap-1 `}>
                <FontAwesomeIcon className="h-2" icon={faInfoCircle}></FontAwesomeIcon>
                {msg == "success" ?
                    <p className="text-xs ">valid</p>
                    :
                    <p className="text-xs ">{msg}</p>
                }
            </div> : ""}
    </>
}


export default InputErrorComponent;