
import './App.css';
import { RouterProvider } from 'react-router-dom';
import homeRouter from './routes/homeRoutes';
function App() {
  
    return <>
      <RouterProvider router={homeRouter}>
      </RouterProvider>

  </>
  
}

export default App;
