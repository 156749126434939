
import { AccountContext } from "../../context/AccountContext";
import TransactionService from "../../api/services/TransactionService";
import ModalRootComponent from "./ModalRootComponent";
import CTAButtonComponent from "../CTAButtonComponent";
import InputComponent from "../InputComponent";
import { toast } from "react-toastify";
import { useState, useContext, useEffect } from "react";
import useAccounts from "../../hooks/useAccount";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faInfoCircle, faLink, faSearchMinus, faSpinner } from "@fortawesome/free-solid-svg-icons";
import PaymentLinkService from "../../api/services/PaymentLinkService";
import DiscountCodeService from "../../api/services/DiscountCodeService";
import SelectComponent3 from "../SelectComponent3";
import CustomerSelectComponent from "../CustomerSelectComponent";
import Constants from "../../constants/Constants";

let NewDiscountCodeComponent = ({ setIsModalActive }) => {
    const navigate = useNavigate();

    let { customers, paymentLinks } = useContext(AccountContext)

    let [emailAddress, setEmailAddress] = useState(null);
    //let []
    let [percentage, setPercentage] = useState(0);
    let [description, setDescription] = useState(null);
    let [discountCodeEmailAddressRecipient, setDiscountCodeEmailAddressRecipient] = useState(null);
    let [discountCodeCustomerIdRecipient, setDiscountCodeCustomerIdRecipient] = useState(null);
    let [customCode, setCustomCode] = useState(null);
    let [useCustomCode, setUseCustomCode] = useState(false);
    let { fetchDiscountCodes } = useAccounts();

    let [errorMsg, setErrorMsg] = useState("");

    let [isCreatingLink, setIsCreatingLink] = useState(false);
    let [generatedLink, setGeneratedLink] = useState(null);

    let [paymentLink, setPaymentLink] = useState(null);
    let [customerList, setCustomerList] = useState([]);
    let [selectAllCustomers, setSelectAllCustomers] = useState(false);
    let [searchText, setSearchText] = useState(null);
    let [searchResult, setSearchResult] = useState([])

    useEffect(() => {


    })



    let handleSelectAllCustomers = async (e) => {

        let all = document.querySelectorAll(".customerSelCheck");
        if (e.target.checked === true) {
            for (let i = 0; i < all.length; i++) {
                console.log(all[i].checked);
                all[i].checked = true;
                setCustomerList(customers);

            }
        }
        else if (e.target.checked == false) {
            for (let i = 0; i < all.length; i++) {
                console.log(all[i].checked);
                all[i].checked = false;
                setCustomerList([]);

            }
        }

    }

    function flattenAndRemoveDuplicates(arr) {
        return arr.reduce((acc, item) => {
            const existingItem = acc.find(obj => obj.customerId === item.customerId);
            if (!existingItem) {
                acc.push(item);
            }
            return acc;
        }, []);
    }

    let handleSearchChange = async (e) => {
        let all = document.querySelectorAll(".customerSelCheck");
        for (let i = 0; i < all.length; i++) {
            console.log(all[i].checked);
            all[i].checked = false;

        }

        setSearchText(e.target.value);

        let regex = new RegExp(`${e.target.value}`);
        if (regex) {
            //console.log(customers)
            let emailAddressSearch = customers.find(o => regex.test(o.emailAddress));
            let mobileNumberSearch = customers.find(o => regex.test(o.mobileNumber));
            let fullNameSearch = customers.find(o => regex.test(o.fullName));
            let r = [mobileNumberSearch ? mobileNumberSearch : [], emailAddressSearch ? emailAddressSearch : [], fullNameSearch ? fullNameSearch : []]
            let results = flattenAndRemoveDuplicates(r);
            setSearchResult(results)
            return
        }



    }

    let handleAdd = async (e, customerId) => {

        console.log("handle add")

        let a = customerList;
        let findId = customerList.find(o => o.customerId == customerId);

        if (e.target.checked == true) {
            if (findId) return;
            else {

                let cus = customers.find(o => o.customerId === customerId);
                a.push(cus);
                setCustomerList(a);


            }
        }

        else if (e.target.checked == false) {
            let f = a.findIndex(o => o.customerId === customerId);
            if (f > -1) {
                a.splice(f, 1);
                console.log(a)
                setCustomerList(a)
            }

        }
    }

    let handleNewDiscountCode = async () => {
        if (isCreatingLink) {
            return;
        }
        setIsCreatingLink(true);

        if (!/^[0-9a-zA-Z-]+$/.test(customCode)) {
            setIsCreatingLink(false);
            toast.error("Custom discount code is invalid. Ensure there are no spaces. \n Allowed characters  : -");
            return;
        }
        if (!isNaN(percentage)) {
            if (percentage <= 0) {
                setIsCreatingLink(false);
                toast.error("Invalid discount percentage");
                return;
            }
            if (percentage > 100) {
                setIsCreatingLink(false);
                toast.error("Invalid discount percentage");
                return;
            }
        }

        try {
            let newlink = await DiscountCodeService.new(
                description,
                percentage,
                discountCodeEmailAddressRecipient,
                discountCodeCustomerIdRecipient,
                customCode,
                customerList,
                paymentLink
            );
                console.log(paymentLink)
            if (newlink.success) {
                toast.success(newlink.msg);
                fetchDiscountCodes();
                console.log(newlink);
            }
            else {
                toast.error(newlink.msg)
            }
        }
        catch (e) {
            console.log(e);
        }

        setIsCreatingLink(false)


    }
    return <ModalRootComponent
        children={
            <div className="z20 font-lota flex flex-col lota-font rounded-t-md border border-t-2 border-t-rouzo-green-light self-center bg-white">
                <div className="flex flex-row justify-end text-right p-1 pb-0 hover:cursor-pointer hover:text-xl">
                    <p onClick={() => setIsModalActive(false)} className="">
                        <FontAwesomeIcon className="text-md" icon={faClose}></FontAwesomeIcon>
                    </p>
                </div>
                <div className="flex flex-col p-6 pt-1 gap-2">
                    <div className="text-center">
                        <p className="font-bold text-md text-rouzo-green-light">Generate Discount Coupon</p>
                    </div>
                    <div className="flex flex-col  gap-2 ">
                        <div className="md:flex-row flex-col flex gap-2 ">
                            <div className="flex flex-col">
                                <p className="text-xs text-center md:text-left">Description</p>
                                <div className="flex flex-row md:justify-start justify-center">
                                    <p className="text-orange-400 bg-orange-50 text-[8px] rounded-md px-1 flex flex-row gap-1 items-center">
                                        <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>
                                        Describe your discount code
                                    </p>
                                </div>
                                {isCreatingLink ? <InputComponent disabled={true} value={description} ></InputComponent> : <InputComponent onChange={(e) => setDescription(e.target.value)}></InputComponent>}
                            </div>
                            <div className="flex flex-col">
                                <div className="flex flex-row gap-1 justify-center md:justify-start" >
                                    <input onChange={(e) => setUseCustomCode(e.target.checked)} type="checkbox"></input>
                                    <p className="text-xs text-center md:text-left">Use Custom Code</p>
                                </div>
                                <div className="flex flex-row md:justify-start justify-center">
                                    <p className="text-orange-400 bg-orange-50 text-[8px] rounded-md px-1 flex flex-row gap-1 items-center">
                                        <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>
                                        Use a custom discount code e.g XMAS20-OFF
                                    </p>
                                </div>
                                {(isCreatingLink || useCustomCode == false) ? <InputComponent disabled={true} value={customCode} ></InputComponent> : <InputComponent onChange={(e) => setCustomCode(e.target.value)}></InputComponent>}
                            </div>
                            <div className="flex flex-col">
                                <p className="text-xs text-center  md:text-left">Percentage</p>
                                <div className="flex flex-row justify-center md:justify-start">
                                    <p className="text-orange-400 bg-orange-50 text-[8px] rounded-md px-1 flex flex-row gap-1 items-center">
                                        <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>
                                        Discount at which customer will pay at
                                    </p>
                                </div>
                                {isCreatingLink ? <InputComponent disabled={true} value={percentage}></InputComponent> : <InputComponent type="number" onChange={(e) => setPercentage(e.target.value)}></InputComponent>}
                            </div>
                            <div className="flex flex-col">
                                <p className="text-xs text-center md:text-left">Attach Payment Link</p>
                                <div className="flex flex-row md:justify-start justify-center">
                                    <p className="text-blue-400 bg-blue-50 text-[8px] rounded-md px-1 flex flex-row gap-1 items-center">
                                        <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>
                                        Add payment link to be sent to customer(s)
                                    </p>
                                </div>
                                {isCreatingLink ? <InputComponent disabled={true} value={description} ></InputComponent> :
                                    <select className="p-1 px-4 bg-rouzo-light-blue-2 rounded-md text-xs" onChange={(e) => setPaymentLink(e.target.value)}>
                                        <option value={null}>Select a payment link</option>
                                        {paymentLinks ? paymentLinks.map((paymentLink) => {
                                            return <option value={paymentLink.paymentLinkId} className="flex flex-col items-center gap-2 ">
                                               <p className="text-xs font-bold px-2">{paymentLink.description}</p> 
                                               <p className="bg-slate-200 rounded-sm px-1"> {Constants.nairaString}{paymentLink.amount}</p>
                                            </option>
                                        })
                                            : "No payment link"}
                                    </select>
                                }
                            </div>
                        </div>

                        <hr>
                        </hr>
                        <div className="">
                            <p className="text-left font-bold text-xs">Send To</p>
                            <div className="flex flex-col md:flex-row gap-2 ">
                                <div className="flex flex-col gap-1">
                                    <p className="text-xs text-left">Customer</p>

                                    <div className="rounded-md flex flex-row ">
                                        <input onChange={(e) => handleSearchChange(e)} className="px-2 py-0 bg-slate-200/30" type="search"></input>
                                        <p className="px-1 rounded-md text-white bg-rouzo-base-color/40">Search</p>
                                    </div>
                                    <div className="rounded-md bg-slate-200/40 p-1 max-h-40 gap-1 overflow-y-scroll">
                                        {searchText ? "" :
                                            <div className="p-2 flex flex-row gap-2 rounded-md items-center bg-slate-200/20 items-center">
                                                <input onChange={(e) => handleSelectAllCustomers(e)} type="checkbox"></input>
                                                <p className="font-regular text-sm">All Customers</p>
                                            </div>
                                        }
                                        <hr></hr>
                                        <div >

                                            {
                                                searchText ?
                                                    <div className="">
                                                        {
                                                            searchResult ?
                                                                searchResult.length > 0 ?
                                                                    searchResult.map(customer => {
                                                                        return <div className="p-2 flex flex-row gap-2 rounded-md items-center bg-slate-200/20 items-center">
                                                                            <input onChange={(e) => handleAdd(e, customer.customerId)} className="customerSelCheck" type="checkbox"></input>
                                                                            <div className="flex flex-col text-left">
                                                                                <p className="font-thin text-sm">{customer ? customer.emailAddress : "n/a"}</p>
                                                                                <p className="text-xs ">{customer ? customer.fullName : "n/a"}</p>
                                                                            </div>

                                                                        </div>
                                                                    })
                                                                    :
                                                                    <div className="flex flex-col gap-0">
                                                                        <FontAwesomeIcon className="text-slate-400" icon={faSearchMinus}></FontAwesomeIcon>
                                                                        <p className="text-xs font-thin text-slate-400">Oops! customer not found</p>
                                                                    </div>
                                                                :
                                                                ""
                                                        }

                                                    </div>
                                                    :
                                                    customers ? customers.map(customer => {
                                                        return <div className="p-2 flex flex-row gap-2 rounded-md items-center bg-slate-200/20 items-center">
                                                            <input onChange={(e) => handleAdd(e, customer.customerId)} className="customerSelCheck" type="checkbox"></input>
                                                            <div className="flex flex-col text-left">
                                                                <p className="font-thin text-sm">{customer.emailAddress}</p>
                                                                <p className="text-xs ">{customer.fullName}</p>
                                                            </div>

                                                        </div>
                                                    })
                                                        :
                                                        ""}

                                        </div>
                                    </div>
                                    {/*<CustomerSelectComponent onChange={(e) => setDiscountCodeCustomerIdRecipient(e.target.value)} data={customers}></CustomerSelectComponent>*/}

                                </div>
                                <div className="text-left flex flex-col gap-1">
                                    <p className="text-xs">Email address</p>
                                    <InputComponent onChange={(e) => setDiscountCodeEmailAddressRecipient(e.target.value)}></InputComponent>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row justify-end">
                            <div onClick={() => handleNewDiscountCode()} className={`flex flex-row text-white rounded-md p-2 gap-2 ${isCreatingLink ? "bg-green-800" : "bg-rouzo-green-light  hover:border-2 hover:cursor-pointer hover:border-rouzo-green-light hover:text-rouzo-green-light hover:bg-white"}`}>
                                {isCreatingLink ? <FontAwesomeIcon spin={true} icon={faSpinner}></FontAwesomeIcon> : <FontAwesomeIcon icon={faLink}></FontAwesomeIcon>}
                                <p className="text-xs text-left font-bold ">{isCreatingLink ? "Generating.." : "Generate"}</p>

                            </div>
                        </div>

                        <div className="">

                        </div>
                    </div>
                </div>

            </div>
        }
    >

    </ModalRootComponent>
}


export default NewDiscountCodeComponent;