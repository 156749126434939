import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom"
import PaymentLinkService from "../../api/services/PaymentLinkService";
import Constants from "../../constants/Constants";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight, faLock, faSpinner } from "@fortawesome/free-solid-svg-icons";
import InputComponent from "../../components/InputComponent";
import LogoImg from "../../assets/img/logo.svg";
import InputComponentFilled from "../../components/InputComponentFilled";

let PaymentLinkPage = () => {

    let { paymentLinkId, referredBy } = useParams();
    let [amount, setAmount] = useState(null);
    let [description, setDescription] = useState(null);
    let [isResolvingLink, setIsResolvingLink] = useState(false);
    let [resolvedLink, setResolvedLink] = useState(false);

    let [pointValueInNaira, setPointValueInNaira] = useState(null);
    let [useDiscountCode, setUseDiscountCode] = useState(false);
    let [discountCode, setDiscountCode] = useState("");

    let [isResolvingDiscountCode, setIsResolvingDiscountCode] = useState(false);
    let [resolvedDiscountCode, setResolvedDiscountCode] = useState(false);
    let [discountPercentage, setDiscountPercentage] = useState(0);


    let [resolvedEmailAddress, setResolvedEmailAddress] = useState(false);
    let [isResolvingEmailAddress, setIsResolvingEmailAddress] = useState(false);

    let [customerExist, setCustomerExist] = useState(false);
    let [resolvedFullName, setResolvedFullName] = useState("");
    let [resolvedMobileNumber, setResolvedMobileNumber] = useState("");

    let [loyaltyPointsToUse, setLoyaltyPointsToUse] = useState(null);
    let [useLoyaltyPoints, setUseLoyaltyPoints] = useState(false);
    let [loyaltyPoints, setLoyaltyPoints] = useState(0);


    let [isLoading, setIsLoading] = useState(false);


    let [emailAddress, setEmailAddress] = useState(null);
    let [fullName, setFullName] = useState(null);
    let [mobileNumber, setMobileNumber] = useState(null);

    let getPaymentLink = async () => {
        if (isResolvingLink) {
            return;
        }
        setIsResolvingLink(true);
        let linkDetails = await PaymentLinkService.fetchPaymentLink(paymentLinkId);
        if (linkDetails.success) {
            setAmount(linkDetails.data.amount);
            setDescription(linkDetails.data.description);
            setIsResolvingLink(false);
            setResolvedLink(true);
            setPointValueInNaira(linkDetails.data.settings.pointValueInNaira ? linkDetails.data.settings.pointValueInNaira : 0);
        }
        else if (linkDetails.msg.includes("invalid")) {
            toast.error(linkDetails.msg + "\n Redirecting..");
            setIsResolvingLink(false);
            setResolvedLink(true)
            setTimeout(() => {
                window.location = "/"
            }, 2000)

        }
        else {
            toast.error(linkDetails.msg)
            setIsResolvingLink(false);
            setResolvedLink(true)
        }
    }
    let handleEmailAddress = async (email) => {
        setEmailAddress(email);

        //handleEmailResolve()
    }

    let handleCheckout = async () => {
        if (isLoading) {
            return;
        }
        setIsLoading(true);
        console.log({
            emailAddress: emailAddress,
            fullName: fullName,
            mobileNumber: mobileNumber,
            discountCode: discountCode,
            loyaltyPointsToUse: loyaltyPointsToUse,
            useLoyaltyPoints: useLoyaltyPoints ? useLoyaltyPoints : false,
            referredBy: referredBy ? referredBy : null
        })
        try {
            let payGatewayUrl = await PaymentLinkService.initiatePayment(paymentLinkId, {
                emailAddress: emailAddress,
                fullName: fullName,
                mobileNumber: mobileNumber,
                discountCode: discountCode,
                loyaltyPointsToUse: loyaltyPointsToUse,
                useLoyaltyPoints: useLoyaltyPoints ? useLoyaltyPoints : false,
                referredBy: referredBy ? referredBy : null
            });

            if (payGatewayUrl.success) {
                toast.success("Redirecting to payment gateway..");
                window.location = payGatewayUrl.data.url.authorization_url
                //console.log(payGatewayUrl)
            }
            else {
                toast.error(payGatewayUrl.msg);
            }
            setIsLoading(false);
        }
        catch (e) {
            console.log(e);
            setIsLoading(false);
            toast.error("Could not connect to payment gateway.")
        }
    }
    let handleDiscountCodeResolve = async () => {
        if (isResolvingDiscountCode) {
            return;
        }

        if (!discountCode) {
            return toast.error("Invalid discount code");
        }
        setIsResolvingDiscountCode(true)
        try {
            let resolveCode = await PaymentLinkService.resolveDiscountCode(paymentLinkId, discountCode);
            if (resolveCode.success) {
                setDiscountCode(discountCode);
                setDiscountPercentage(resolveCode.data.percentage);
                setIsResolvingDiscountCode(false);
                setResolvedDiscountCode(true)
            }
            else {
                toast.error(resolveCode.msg);
                setIsResolvingDiscountCode(false);
            }
        }
        catch (e) {
            console.log(e);
        }

    }
    let toggleUsePoints = async (e) => {
        console.log(e.target.checked);
        setUseLoyaltyPoints(e.target.checked);
    }
    let toggleUseDiscount = async (e) => {
        console.log(e.target.checked);
        setUseDiscountCode(e.target.checked);
        if(e.target.checked == false){
            setDiscountCode(null);
        }
    }
    let handleEmailResolve = async () => {
        if (isResolvingEmailAddress) {
            return
        }
        if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(emailAddress)) {
            setIsResolvingEmailAddress(true)
            let resEmail = await PaymentLinkService.resolveEmailAddress(emailAddress, paymentLinkId);
            if (resEmail.success) {
                setLoyaltyPoints(resEmail.data.loyaltyPoints)
                setCustomerExist(resEmail.data.customerExist);
                setResolvedFullName(resEmail.data.fullName);
                setResolvedMobileNumber(resEmail.data.mobileNumber);
                setResolvedEmailAddress(true);
            }
            else {

            }
            setIsResolvingEmailAddress(false)
        }
        else {
            toast.error("Invalid email address")
        }
        setIsResolvingEmailAddress(false)


    }
    useEffect(() => {
        if (paymentLinkId && !resolvedLink) {
            getPaymentLink()
        }
        /*if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(emailAddress)) {
            if (!resolvedEmailAddress && !isResolvingLink) {
                handleEmailResolve();
            }
        }*/
    })



    return <>
        <div className="flex h-screen w-screen items-center justify-center flex-row ">
            <div className="self-center p-4 flex flex-col gap-4 rounded-md rounded-b-xl bg-slate-50 outline-b-dotted outline-2 outline-slate-500 border-t-[20px] border-t-rouzo-deep-blue">
                <div className="flex flex-col gap-8 ">
                    <div className="flex flex-col gap-2 items-end">
                        <p className="bg-rouzo-deep-blue font-bold px-2 text-white text-xs rounded-md">{amount ? `${Constants.nairaString} ${amount}` : "Loading.."}</p>
                        <p className="rounded-md bg-slate-100 text-black-400 gap-2 text-xs px-2  ">{paymentLinkId}</p>

                    </div>

                    {isResolvingLink ?
                        <FontAwesomeIcon className="" icon={faSpinner} spin={true}></FontAwesomeIcon>
                        :
                        <p className="px-2 text-black  text-md font-bold rounded-md">{description ? `${description}` : "Loading.."}</p>
                    }
                    {/*<hr></hr>*/}
                    {
                        customerExist ?
                            <p className="font-regular  ">
                                Welcome back,  &nbsp;
                                <span className="font-bold underline underline-offset-1">
                                    {resolvedFullName}
                                </span>
                            </p>
                            : ""
                    }
                    {
                        (resolvedEmailAddress && !customerExist) ?
                            <p className="font-regular  ">
                                Hello customer,  &nbsp;
                            </p>
                            : ""
                    }
                </div>
                <div className="">
                    <div className="outline-dotted outline-2 outline-slate-500 rounded-xl p-4 gap-2 flex flex-col">
                        <div className="flex flex-col">
                            <p className="text-xs text-left font-bold text-rouzo-deep-blue">Email address</p>
                            <div className="flex flex-row gap-2 ">
                                <InputComponent required placeholder={"email address"} onChange={(e) => handleEmailAddress(e.target.value)}></InputComponent>
                                {isResolvingEmailAddress ?
                                    <p className="px-2 py-1 text-center items-center text-black bg-green-300 rounded-md flex flex-row gap-2 font-regular text-xs">
                                        <p>Verifying..</p>
                                        <FontAwesomeIcon className="text-green-700" icon={faSpinner} spin={true}></FontAwesomeIcon>
                                    </p>
                                    :
                                    resolvedEmailAddress ?
                                        ""
                                        :
                                        <p onClick={() => handleEmailResolve()} className="px-2 py-1 text-center item-center text-white bg-rouzo-green-light rounded-md font-bold">Next </p>
                                }
                            </div>
                        </div>
                        {resolvedEmailAddress ?
                            <div className="flex flex-col gap-2">
                                <div className="flex flex-col md:flex-row gap-2">
                                    <div className="flex flex-col justify-start">
                                        <p className="text-xs text-left font-bold text-rouzo-deep-blue">Full Name</p>
                                        {resolvedFullName ?
                                            <InputComponentFilled required value={resolvedFullName} ></InputComponentFilled>
                                            :
                                            <InputComponent required placeholder={"Full Name"} onChange={(e) => setFullName(e.target.value)}></InputComponent>
                                        }
                                    </div>
                                    <div className="flex flex-col justify-end ">
                                        <p className="text-xs text-left font-bold text-rouzo-deep-blue">Mobile Number</p>
                                        {resolvedMobileNumber ?
                                            <InputComponentFilled required placeholder={"Mobile Number"} value={resolvedMobileNumber}></InputComponentFilled>
                                            :
                                            <InputComponent required placeholder={"Mobile Number"} onChange={(e) => setMobileNumber(e.target.value)}></InputComponent>
                                        }
                                    </div>
                                </div>
                                <hr className="px-2"></hr>
                                <div >
                                    <p className="font-regular text-left text-xs font-bold">Points </p>
                                    <div className="flex flex-col gap-2 text-sm mt-2 bg-slate-200 rounded-md p-1">
                                        <div className="flex flex-row justify-between items-center">
                                            <p className="text-xs font-regular">Available points</p>
                                            <p className="font-bold text-rouzo-deep-blue">{loyaltyPoints ? loyaltyPoints : 0}</p>
                                        </div>
                                        <div className="flex flex-row justify-between items-center">
                                            <p className="text-xs font-regular">Use Loyalty points</p>
                                            <input onChange={(e) => toggleUsePoints(e)} type="checkbox"></input>
                                        </div>
                                        {
                                            useLoyaltyPoints == true ?
                                                <div>
                                                    <div className="flex flex-row justify-between items-center">
                                                        <p className="text-xs font-regular">Use points</p>
                                                        <InputComponent className="text-right" placeholder="points to use" onChange={(e) => setLoyaltyPointsToUse(e.target.value)}></InputComponent>
                                                    </div>
                                                    <div className="flex flex-row justify-between items-center">
                                                        <p className="text-xs font-regular">Amount slashed</p>
                                                        <p className="font-bold text-rouzo-green-light line-through">-{Constants.nairaString}{loyaltyPointsToUse * pointValueInNaira}</p>
                                                    </div>
                                                </div>
                                                :
                                                ""
                                        }
                                    </div>
                                </div>
                                <div >
                                    <p className="font-regular text-left text-xs font-bold">Discount </p>
                                    <div className="flex flex-row justify-between items-center">
                                        <p className="text-xs font-regular">Use Discount</p>
                                        <input onChange={(e) => toggleUseDiscount(e)} type="checkbox"></input>
                                    </div>
                                    {
                                        useDiscountCode == true ?
                                            <div className="flex flex-row gap-2">
                                                <InputComponent required placeholder={"Discount code"} onChange={(e) => setDiscountCode(e.target.value)}></InputComponent>
                                                {isResolvingDiscountCode ?
                                                    <p className="px-2 py-1 text-center items-center text-white bg-blue-400 rounded-md flex flex-row gap-2 font-regular text-xs">
                                                        <p>Applying code..</p>
                                                        <FontAwesomeIcon className="text-white" icon={faSpinner} spin={true}></FontAwesomeIcon>
                                                    </p>
                                                    :
                                                    resolvedDiscountCode ?
                                                        ""
                                                        :
                                                        <p onClick={() => handleDiscountCodeResolve()} className="hover:cursor-pointer hover:bg-blue-400 px-2 py-1 text-center flex items-center text-white text-xs bg-rouzo-deep-blue rounded-md font-bold">Apply Discount </p>
                                                }

                                            </div>
                                            :
                                            ""
                                    }
                                    {resolvedDiscountCode ?
                                        <div className="flex flex-col gap-2 text-sm mt-2 bg-slate-200 rounded-md p-1">
                                            <div className="flex flex-row justify-between items-center">
                                                <p className="text-xs font-regular">Discount percentage</p>
                                                <p className="font-bold text-rouzo-deep-blue">{discountPercentage}%</p>
                                            </div>
                                            <div className="flex flex-row justify-between items-center">
                                                <p className="text-xs font-regular">Discount slashed amount</p>
                                                <p className="font-bold text-rouzo-green-light line-through">-{Constants.nairaString}{discountPercentage * amount}</p>
                                            </div>
                                        </div>
                                        : ""
                                    }
                                </div>
                                <div className="flex flex-row ">
                                    {isLoading ?
                                        <p className={`flex flex-row bg-slate-200 rounded-md py-1 px-2 gap-2 text-right text-rouzo-green-light  items-center`}>

                                            <p className="font-regular text-xs">Connecting to payment gateway</p>
                                            <FontAwesomeIcon className="h-4" spin={true} icon={faSpinner}></FontAwesomeIcon>
                                        </p>
                                        :
                                        <p onClick={() => handleCheckout()} className={`flex flex-row bg-slate-200 rounded-md py-1 px-2 gap-2 text-right text-rouzo-green-light hover:cursor-pointer hover:bg-rouzo-green-light hover:text-white items-center`}>

                                            <p className="font-bold">Proceed to Checkout</p>
                                            <FontAwesomeIcon className="h-6" icon={faArrowCircleRight}></FontAwesomeIcon>
                                        </p>
                                    }


                                </div>
                            </div>
                            :
                            ""
                        }
                    </div>
                    <div className="flex flex-col items-center mt-6 justify-center gap-1">
                        <p className="flex flex-row items-center text-xs gap-1">
                            <FontAwesomeIcon className="text-rouzo-green-light" icon={faLock}></FontAwesomeIcon>
                            <p>Secured by</p>
                        </p>
                        <div className="flex flex-row gap-2 items-center">
                            <p className="flex flex-row gap-0 items-center">
                                <img className="h-5" src={LogoImg}></img>
                                <p className="text-xs font-bold text-rouzo-base-color">Boost</p>
                            </p>
                            <p className="font-bold text-rouzo-green-light">paystack</p>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    </>
}


export default PaymentLinkPage