import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { faCancel, faCirclePause, faInfoCircle, faSpinner, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate, useParams } from "react-router-dom";
import Logo from "../../assets/img/logo.svg"
import AuthService from "../../api/services/AuthService";
import { useState } from "react";
import { toast } from "react-toastify";

let EmailVerificationStatusPage = () => {

    let { status, message, emailAddress } = useParams();
    let [isLoading, setIsLoading] = useState(false);
    
    const navigate = useNavigate();

    let handleResendCode = async () => {
        if(isLoading){
            return;
        }
        setIsLoading(true)
        console.log(emailAddress)
        let ver = await AuthService.resendCode(message);


        //setRanVerificationProcess(true);
        if (ver.success) {
            toast.success("We just sent you a new verification link");
            setIsLoading(false);
            navigate(`/verify/pending/${message}`);
        }
        else {
            setIsLoading(false);
            navigate(`/verify/failed/${message}`);
        }

    }

    return <>
        
        {status == "success" ? <div>
            <div className="flex h-screen justify-center flex-col items-center lota-font font-lota">

                <div className="flex flex-row  rounded-xl ">
                    <div className="flex flex-col bg-rouzo-green-light rounded-l-xl  shadow-2xl p-8  items-center">
                        <img className="h-24" src={Logo}></img>
                    </div>
                    <div className=" flex flex-col justify-center bg-rouzo-deep-blue rounded-r-xl items-center p-8  gap-4">
                        <p className="">
                            <div className="flex flex-col  text-center justify-around">
                                <FontAwesomeIcon className="text-rouzo-green-light text-xl" icon={faThumbsUp}></FontAwesomeIcon>
                                <div className="flex justify-center">
                                    <span className="text-center text-xs text-rouzo-deep-blue bg-rouzo-green-light rounded-sm px-1 py-0">success</span>
                                </div>
                            </div>


                        </p>
                        <div>
                            <p className="text-white font-bold text-xl">{message}</p>
                            <Link to={"/login"}>
                                <p className="text-xs font-bold text-white  underline underline-offset-0">Proceed to login page</p>
                            </Link>
                        </div>
                    </div>

                </div>

            </div>
        </div> : ""}
        {status == "failed" ? <div>
            <div className="flex h-screen justify-center flex-col items-center lota-font font-lota">

                <div className="flex flex-row  rounded-xl ">
                    <div className="flex flex-col bg-red-300 rounded-l-xl  shadow-2xl p-8  items-center">
                        <img className="h-24" src={Logo}></img>
                    </div>
                    <div className=" flex flex-col justify-center bg-rouzo-deep-blue rounded-r-xl items-center p-8  gap-2">
                        <p className="flex flex-col gap-2">
                            <div className="flex flex-col  text-center justify-around">
                                <FontAwesomeIcon className="text-red-300 text-xl" icon={faCirclePause}></FontAwesomeIcon>
                                <div className="flex justify-center">
                                    <span className="text-center text-xs text-rouzo-deep-blue bg-red-300 rounded-sm px-1 py-0">failed</span>
                                </div>
                            </div>

                            <p className="text-red-300 text-sm">Oops!! Email Verification Failed</p>
                        </p>
                        <div>
                            <p className="text-gray-400">Did not get a link? <span className="underline underline-offset-1 hover:cursor-pointer text-rouzo-base-color" onClick={() => handleResendCode()}> Resend Link</span></p>
                        </div>
                    </div>

                </div>

            </div>
        </div> : ""}

        {status == "pending" ? <div>
            <div className="flex h-screen justify-center flex-col items-center lota-font font-lota">

                <div className="flex flex-row  rounded-xl ">
                    <div className="flex flex-col bg-rouzo-overlay rounded-l-xl  shadow-2xl p-8  items-center">
                        <img className="h-24" src={Logo}></img>
                    </div>
                    <div className=" flex flex-col justify-center bg-rouzo-deep-blue rounded-r-xl items-center p-8  gap-2">
                        <p className="flex flex-col gap-2">
                            <div className="flex flex-col  text-center justify-around">
                                <FontAwesomeIcon className="text-white text-xl" icon={faCirclePause}></FontAwesomeIcon>
                                <div className="flex justify-center">
                                    <span className="text-center text-xs text-rouzo-deep-blue bg-white rounded-sm px-1 py-0">pending</span>
                                </div>
                            </div>

                            <p className="text-white text-sm">Kindly check your mail for your verification link.</p>
                            {isLoading ? <FontAwesomeIcon className="h-4 text-rouzo-base-color" spinPulse={true} icon={faSpinner} spin />  : ""}
                        </p>
                        <div>
                            <p  className="text-gray-400">Did not get a link? <span className="underline underline-offset-1 hover:cursor-pointer text-rouzo-base-color"  onClick={() => handleResendCode()}> Resend Link</span></p>
                        </div>
                    </div>

                </div>

            </div>
        </div> : ""}
    </>
}

export default EmailVerificationStatusPage;