

let InputComponentFilled = (props) => {
    let {value} = props;
    return <>
       <div>
            <p  className="p-1 px-4 text-left bg-rouzo-light-blue-2 rounded-md">{value}</p>
        </div>
    </>
}

export default InputComponentFilled