//import { useState } from "react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

let CTAButtonComponent = ({ isLoading, text, icon, goTo, flip, rotation, onClick }) => {
    return <>
        <div onClick={onClick} className="py-3 px-[22px] flex flex-row gap-3 bg-rouzo-base-color rounded-lg hover:cursor-pointer  ">
            {icon ?
                <FontAwesomeIcon className="items-center self-center" rotation={rotation} icon={icon} flip={flip} style={{ color: "white" }} />
                : ""}
            <p className="text-sm md:text-base text-white mulish-font font-bold">{text}</p>
            {isLoading ? <FontAwesomeIcon icon={faSpinner} spin={true} className="text-white text-xs"></FontAwesomeIcon> : ""}
        </div>
    </>
}


export default CTAButtonComponent;