import Constants from "../../constants/Constants";
import axios from "../axios";
import RequestService from "./RequestService";
// import { checkToken } from "../utils/Utils";
import authHeader from "./auth-header";
const AuthService = {
    login: async (data) => {
        let url = Constants.LOGIN.INITIATE;
        let req =  await axios.post(url,data);
        if(req.data){
            let {type,data} = req.data;
            console.log(type)
        }
        return req.data;
    },
    onboard : async (data) => {
        let url = Constants.SIGNUP;
        let req =  await RequestService(url,true,data,false);
        /*if(req.data){
            let {type,data} = req.data;
           return req.data
        }*/
        return req;
    },
    
    verifyEmail : async (emailAddress,code) => {
        let url = Constants.VERIFY_EMAILADDRESS(emailAddress, code);
        let req =  await RequestService(url,true,{},false);
        
        return req;
    },
    resendCode : async (emailAddress) => {
        let url = Constants.ONBOARD.RESEND_CODE;
        let req =  await RequestService(url,true,{
            emailAddress : emailAddress
        },false);
        
        return req;
    },
    getloggedInUserDetails: async () => {
        return await axios
            .get(`/user/get/me`, {
                headers: { Authorization: authHeader() },
            })
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                console.log(err.message);
            });
    },
    confirmOtp : async (otpFor, data) => {
        let url = "";
        let {emailAddress, mobileNumber} = data;
        console.log(otpFor)
        switch (otpFor) {
            case "onboard":
                url = mobileNumber ? Constants.ONBOARD.CONFIRM_OTP.MOBILE_NUMBER : (emailAddress ?  Constants.ONBOARD.CONFIRM_OTP.EMAIL_ADDRESS : "");
                break;
            case "login" : 
                url = mobileNumber ? Constants.LOGIN.CONFIRM_OTP.MOBILE_NUMBER : (emailAddress ?  Constants.LOGIN.CONFIRM_OTP.EMAIL_ADDRESS : "");
                break;
            default:
                url = Constants.OTP.CONFIRM_OTP
                break;
        }

        let req = await axios.post(url, data)
        return req.data;
    },
    getUser: () => {
        let user = localStorage.getItem("user")
            ? JSON.parse(localStorage.getItem("user"))
            : null;
        return user;
    },
    logout: () => {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        localStorage.removeItem("__permifyUser");
    },
    
    passwordReset : {
        initiate : async (emailAddress) => {
            let url = Constants.PASSWORD_RESET.GENERATE;
            let req =  await axios.post(url, {
                emailAddress : emailAddress
            });
            if(req.data){
                let {type,data} = req.data;
                console.log(type)
            }
            return req.data;
        },
        verify : async (code) => {
            let url = Constants.PASSWORD_RESET.VERIFY(code);
            let req =  await axios.post(url,{});
            if(req.data){
                let {type,data} = req.data;
                console.log(type)
            }
            return req.data;
        },
        reset : async (data) => {
            let url = Constants.PASSWORD_RESET.RESET;
            let req =  await axios.post(url, data);
            if(req.data){
                let {type,data} = req.data;
                console.log(type)
            }
            return req.data;
        }
    }
};

export default AuthService;
