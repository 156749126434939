import { useContext, useEffect } from 'react';
import useAccounts from '../hooks/useAccount';
import { AccountContext } from '../context/AccountContext';
import Constants from '../constants/Constants';
import { Link } from 'react-router-dom';


let DiscountCodeListComponent = () => {

    let { fetchPaymentLinks } = useAccounts();
    let { discountCodes } = useContext(AccountContext);


    useEffect(() => {

    })

    return <>
        <div className=''>
            <table className='border-spacing-12 border-collapse' >
                <tr className='text-left flex flex-row gap-4 font-lota p-4 pb-0 items-start font-bold text-xs '>
                    <td className=''>Description</td>
                    <td>Percentage</td>
                    <td>Status </td>
                    <td>Used </td>
                    <td>Code </td>
                </tr>
                {discountCodes ?
                    discountCodes.map(link => {
                        return <>
                            <tr className='rounded-md justify-evenly flex flex-row gap-4 p-4 mb-2 items-center bg-slate-100'>
                                <td className='text-xs flex flex-col gap-1'>
                                    <p className='text-left'>{link.description}</p>
                                    <p className='text-[9px] font-bold'>{link.createdAt ? `${new Date(link.createdAt).toDateString()} ${new Date(link.createdAt).toLocaleTimeString()}` : ""}</p>
                                </td>
                                <td className='text-black font-bold text-sm'>{link.percentageSlug}</td>
                                <td>{link.isActive ?
                                    <p className='px-2 py-0 items-center text-center text-xs rounded-full bg-rouzo-green-light text-white'>active</p>
                                    : <p className='px-2 py-0 text-xs items-center text-center rounded-full bg-gray-400 text-black'>inactive</p>
                                }</td>
                                <td>{link.used === false?
                                    <p className='px-2 py-0 items-center text-center text-xs rounded-full bg-rouzo-green-light text-white'>unused</p>
                                    : <p className='px-2 py-0 text-xs items-center text-center rounded-full bg-gray-400 text-black'>used</p>
                                }</td>
                                <td className='rounded-full px-1 bg-white text-rouzo-green-light font-bold text-md'>{link.discountCode}</td>
                              
                            </tr>
                        </>
                    })

                    : ""}
            </table>
        </div>
    </>
}



export default DiscountCodeListComponent;