
import { motion } from "framer-motion";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { OnboardContext } from "../context/OnboardContext";
let HomePageSidebarComponent = ({setPage,  setSidebarActive }) => {
    let { isLoggedIn } = useContext(OnboardContext)
    return <>
        <motion.div
            onClick={() => setSidebarActive(false)}
            className="z-20 flex flex-row absolute justify-start w-screen h-screen bg-rouzo-overlay "
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.1 }}
        >
            <div className="flex-col  md:hidden bg-rouzo-deep-blue h-screen pt-10">
                <div className="flex p-8 justify-center text-left text-xl gap-4 text-white h-full flex-col">
                    <div className="hover:rouzo-base-color hover:cursor-pointer hover:underline hover:underline-offset-2">
                        <p onClick={() => setPage(1)}>Rewards & Loyalty</p>

                    </div>
                    <div className="hover:rouzo-base-color hover:cursor-pointer hover:underline hover:underline-offset-2">
                        <p onClick={() => setPage(2)} >Payment Links</p>

                    </div>
                    <div className="hover:rouzo-base-color hover:cursor-pointer hover:underline hover:underline-offset-2">
                        <p>Contact Us</p>
                    </div>
                    <div className="">
                        {
                            isLoggedIn ?
                                <div className="flex flex-row gap-2 items-center">
                                    <Link to={"/dashboard"}>
                                        <p className="outline outline-1 outline-white text-white font-thin p-2 text-sm bg-rouzo-base-color">Dashboard</p>
                                    </Link>
                                </div>
                                :
                                <div className="flex flex-col gap-2">
                                    <Link to={"/login"}>
                                        <p className="font-regular text-xl ">Login</p>
                                    </Link>
                                    <Link to={"/onboard"}>
                                        <p className="font-regular text-xl ">Sign up</p>
                                    </Link>
                                </div>
                        }
                    </div>
                </div>
            </div>

        </motion.div>
    </>
}


export default HomePageSidebarComponent;