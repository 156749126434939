
import { motion } from "framer-motion";

let ModalRootComponent2 = ({children}) => {
    return <>
        <motion.div
            className="z-10 flex justify-center  sticky justify-top w-screen px-4 bg-rouzo-overlay "
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
        >
            {children}
            
        </motion.div>
    </>
}

export default ModalRootComponent2;