
import { useEffect, useState } from "react";
import analyticImg from "../assets/img/13.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import InputErrorComponent from "../components/InputErrorComponent";
import useSignIn from "../hooks/useLogin";
import useOnboard from "../hooks/useOnboard";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
let LoginPage = ({ onboardPage }) => {

    let [isLoading, setIsLoading] = useState(false);
    let [isLogin, setIsLogin] = useState(true);

    let [emailAddress, setEmailAddress] = useState(null);
    let [password, setPassword] = useState(null);
    let [firstName, setFirstName] = useState(null);
    let [lastName, setLastName] = useState(null);
    let [businessName, setBusinessName] = useState(null);
    let { signIn } = useSignIn();
    let { onboard } = useOnboard();
    let [isOnboardPage, setIsOnboardPage] = useState(null)

    let [onboardSuccess, setOnboardSuccess] = useState(null);

    let [businessNameError, setBusinessNameError] = useState(null);
    let [firstNameError, setFirstNameError] = useState(null);
    let [lastNameError, setLastNameError] = useState(null);
    let [confirmPassword, setConfirmPassword] = useState(null);
    let [passwordError, setPasswordError] = useState(null)

    let [emailError, setEmailError] = useState(null)

    let switchState = (where) => {

        if (where == "signup") {
            setIsOnboardPage(false);
            setIsLogin(false)
        }
        else {
            if (where == "login") {
                setIsLogin(true);
            }
        }

    }
    let handleLogin = async () => {

        if (isLoading) {
            return;
        }
        setIsLoading(true);

        if (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(emailAddress))) {
            console.log("Invalid mail");
            setEmailAddress(emailAddress.toLowerCase())
            setIsLoading(false);
            return toast.error("Invalid email address");
        }
        let signin = await signIn({
            emailAddress: emailAddress,
            password: password
        })
        console.log(signin)
        if (signin.success) {
            toast.success(signin.msg);
            window.location = "/dashboard";
            setIsLoading(false)

        }
        else if (!signin.success) {
            setIsLoading(false);
            return toast.error(signin.msg);
        }
        else if (signin.networkError) {
            setIsLoading(false);
            console.log(signin.errors)
            return toast.error("An error occured");
        }

    }

    let handleValidations = async (pop) => {
        if (validations.email(emailAddress) == false) {
            setEmailError("Email is invalid");

            if (pop) return false;
        }
        else {
            setEmailError("success")
        }
        let validateBusinessName = validations.name(businessName);
        let validateFirstName = validations.name(firstName);
        let validateLastName = validations.name(lastName);
        if (!validateBusinessName.result) {
            setBusinessNameError(validateBusinessName.msg);
            if (pop) toast.error(validateBusinessName.error)
            if (pop) return false;
        }
        else {
            setBusinessNameError("success")
        }
        if (!validateFirstName.result) {
            setFirstNameError(validateFirstName.msg);
            if (pop) toast.error(validateFirstName.error);
            if (pop) return false;
        }
        else {
            setFirstNameError("success")
        }
        if (!validateLastName.result) {
            setLastNameError(validateLastName.msg);
            if (pop) toast.error(validateLastName.error)
            if (pop) return false;
        }
        else {
            setLastNameError("success")
        }
        let validatePassword = validations.password(password, confirmPassword);
        if (validatePassword.result == false) {
            setPasswordError(validatePassword.msg);
            if (pop) toast.error(validatePassword.error);
            if (pop) return false;
        }
        else {
            setPasswordError("success")
        }


        return true;
    }

    let validations = {
        email: (e) => /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(e),
        name: (nm) => {
            if (!nm) {
                return {
                    result: false,
                    error: "Name is empty",
                    msg: "Name is empty"
                }
            }
            if (/\d/.test(nm) == true) {
                return {
                    result: false,
                    error: "Name contains numbers",
                    msg: "Name is invalid"
                }
            }
            if (/^([A-Za-z]+(?:\s|-)*){3,}$/.test(nm) == false) {
                return {
                    result: false,
                    error: "Name should be more than two letters",
                    msg: "Name is invalid"
                }
            }
            return {
                result: true,
                error: "success",
                msg: "success"
            }
        },
        password: (one, two) => {
            if (!one) {
                return {
                    result: false,
                    error: "Password field is empty",
                    msg: "Password is empty"
                }
            }
            if (one.length < 5) {
                return {
                    result: false,
                    error: "Password character is less than five",
                    msg: "Password is less than five character"
                }
            }
            if (one != two) {
                return {
                    result: false,
                    error: "Passwords do not match",
                    msg: "Password do not match"
                }
            }

            return {
                result: true
            }
        }
    }

    let handleOnboard = async () => {
        if (isLoading) {
            return;
        }
        if ((await handleValidations(true)) == false) {
            return;
        }

        if (validations.e == false) {
            setEmailError("Email is invalid");
            return
        }

        setIsLoading(true);
        let tryOnboard = await onboard({
            firstName: firstName,
            lastName: lastName,
            emailAddress: emailAddress,
            password: password,
            confirmPassword: confirmPassword,
            businessName: businessName
        })

        if (tryOnboard.success) {
            setIsLoading(false);
            setOnboardSuccess(true);
        }

        else if (!tryOnboard.success) {
            setIsLoading(false)
            setOnboardSuccess(false);
        }
        else {
            setIsLoading(false)
            setOnboardSuccess(null)
        }
    }

    useEffect(() => {
        if (onboardPage && isOnboardPage == null) {
            setIsOnboardPage(onboardPage)
        }
        if (isOnboardPage && isLogin == true) {
            switchState("signup");
        }
        handleValidations(false)
    })
    return <>
        <div className="md:grid md:grid-cols-2 h-screen justify-center font-lota">
            <div className="hidden md:flex justify-center bg-rouzo-deep-blue items-center ">

                <div className="z-20" >
                    <p className="font-bold text-[50px] text-white z-20">Rouzo Boost</p>
                </div>
                <img className="absolute h-96 z-0 opacity-20" src={analyticImg}></img>
            </div>
            <div className="flex justify-center text-left flex-col font-lota gap-6 p-4  md:px-40">
                <Link to={"/"}>
                    <div className="md:hidden flex flex-col">
                        <p className="text-2xl font-bold text-rouzo-base-color ">Boost</p>
                        <p className="text-xs text-black">by Rouzo</p>
                    </div>
                </Link>
                <div className="flex flex-row items-center gap-4">
                    <p onClick={() => switchState("login")} className={`${isLogin ? "font-bold text-rouzo-deep-blue text-3xl" : "font-regular text-rouzo-deep-blue text-xs hover:underline hover:underline-offset-1 hover:cursor-pointer hover:text-rouzo-base-color hover:text-md"}`}>Login</p>
                    <p onClick={() => switchState("signup")} className={`${!isLogin ? "font-bold text-rouzo-deep-blue text-3xl" : "font-regular text-rouzo-deep-blue text-xs hover:underline hover:underline-offset-1 hover:cursor-pointer hover:text-rouzo-base-color hover:text-md"}`}>Signup</p>
                </div>


                <div className="flex flex-col gap-6">

                    {isLogin ? <>
                        <div className="flex flex-col gap-2 ">
                            <p className="font-regular text-xs text-rouzo-deep-blue">Email address</p>
                            <input onChange={(e) => setEmailAddress(e.target.value)} className="rounded-md bg-rouzo-blue-light py-2 px-4 " type="email"></input>
                        </div>
                        <div className="flex flex-col gap-2 ">
                            <p className="font-regular text-xs text-rouzo-deep-blue">Password</p>
                            <input onChange={(e) => setPassword(e.target.value)} className="rounded-md bg-rouzo-blue-light py-2 px-4 " type="password"></input>
                        </div>
                        <div className="justify-end text-right">
                            <button onClick={() => handleLogin()} className="bg-rouzo-base-color text-right px-4 py-2 text-white rounded-md flex flex-row gap-2">
                                {isLoading ? "Logging you in .." : "Login"}
                                {isLoading ? <FontAwesomeIcon className="text-white text-xl" icon={faSpinner} spin={true}></FontAwesomeIcon> : ""}
                            </button>
                        </div>
                        <div>
                            <Link to="/password-reset">
                                <p className="text-sm underline underline-offset-1 text-blue-300">Forgot password ?</p>
                            </Link>
                        </div>
                    </>
                        :
                        <>
                            <div className="flex flex-col gap-2 ">
                                <p className="font-regular text-xs text-rouzo-deep-blue">
                                    Business Name
                                    <p className="text-orange-400 bg-orange-50 text-[8px] rounded-md px-1 flex flex-row gap-1 items-center">
                                        <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>
                                        Your business name would be verified
                                    </p>
                                </p>
                                <input onChange={(e) => setBusinessName(e.target.value)} className="rounded-md bg-rouzo-blue-light py-2 px-4 " type="text"></input>
                                <InputErrorComponent msg={businessNameError}></InputErrorComponent>
                            </div>
                            <div className="flex flex-col gap-2 ">
                                <p className="font-regular text-xs text-rouzo-deep-blue">Email address</p>
                                <input onChange={(e) => setEmailAddress(e.target.value)} className="rounded-md bg-rouzo-blue-light py-2 px-4 " type="email"></input>
                                <InputErrorComponent msg={emailError}></InputErrorComponent>
                            </div>
                            <div className="flex flex-col md:flex-row gap-2">
                                <div className="flex flex-col gap-2 ">
                                    <p className="font-regular text-xs text-rouzo-deep-blue">First Name</p>
                                    <input onChange={(e) => setFirstName(e.target.value)} className="rounded-md bg-rouzo-blue-light py-2 px-4 " type="text"></input>
                                    <InputErrorComponent msg={firstNameError}></InputErrorComponent>
                                </div>
                                <div className="flex flex-col gap-2 ">
                                    <p className="font-regular text-xs text-rouzo-deep-blue">Last Name</p>
                                    <input onChange={(e) => setLastName(e.target.value)} className="rounded-md bg-rouzo-blue-light py-2 px-4 " type="text"></input>
                                    <InputErrorComponent msg={lastNameError}></InputErrorComponent>
                                </div>
                            </div>

                            <div className="flex flex-col gap-2 ">
                                <p className="font-regular text-xs text-rouzo-deep-blue">Password</p>
                                <input onChange={(e) => setPassword(e.target.value)} className="rounded-md bg-rouzo-blue-light py-2 px-4 " type="password"></input>
                            </div>
                            <div className="flex flex-col gap-2 ">
                                <p className="font-regular text-xs text-rouzo-deep-blue">Confirm Password</p>
                                <input onChange={(e) => setConfirmPassword(e.target.value)} className="rounded-md bg-rouzo-blue-light py-2 px-4 " type="password"></input>
                                <InputErrorComponent msg={passwordError}></InputErrorComponent>
                            </div>
                            <div className="justify-end text-right">
                                {onboardSuccess == null ?
                                    <button onClick={() => handleOnboard()} className={`${isLoading ? "bg-blue-100" : "bg-rouzo-base-color"} text-right px-4 py-2 text-white rounded-md flex flex-row gap-2`}>
                                        {isLoading ? "Onboarding .." : "Signup"}
                                        {isLoading ? <FontAwesomeIcon className="text-white text-xl" icon={faSpinner} spin={true}></FontAwesomeIcon> : ""}
                                    </button>
                                    :
                                    ""
                                }
                                {onboardSuccess == true ?
                                    <button className={`bg-green-400 text-right hover:cursor-notallowed px-4 py-2 text-white rounded-md flex flex-row gap-2`}>
                                        Successfully signed up
                                        <FontAwesomeIcon className="text-white text-xl" icon={faCheckCircle} ></FontAwesomeIcon>
                                    </button>
                                    :
                                    ""
                                }
                                {onboardSuccess == false ?
                                    <button onClick={() => handleOnboard()} className={`${isLoading ? "bg-blue-100" : "bg-rouzo-base-color"} text-right px-4 py-2 text-white rounded-md flex flex-row gap-2`}>
                                        {isLoading ? "Onboarding .." : "Signup"}
                                        {isLoading ? <FontAwesomeIcon className="text-white text-xl" icon={faSpinner} spin={true}></FontAwesomeIcon> : ""}
                                    </button>
                                    :
                                    ""
                                }
                            </div>
                        </>
                    }
                </div>

            </div>
        </div>
    </>
}


export default LoginPage;