import { Outlet } from "react-router-dom";
import OnboardProvider from "../context/OnboardContext";
import { ToastContainer } from "react-toastify";

const OnboardLayout = ({children}) => {
    return (
        <main className="App">
            {children ? children : <OnboardProvider>
                <ToastContainer/>
                <Outlet />
            </OnboardProvider>}
        </main>
    )
}

export default OnboardLayout