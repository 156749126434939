import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HomeSvg from "./SvgComponents/HomeSvg";
import { faHome } from "@fortawesome/free-solid-svg-icons";


let NavBarComponent = () => {
    return <>
        <div className="rounded-xl border-2 border-white justify-center">
            <div className="flex flex-col">
                <div>
                    <FontAwesomeIcon icon={faHome}></FontAwesomeIcon>
                </div>
            </div>
        </div>
    </>
}

export default NavBarComponent;