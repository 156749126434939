import { useState } from "react";
import Constants from "../../constants/Constants";
import ModalRootComponent from "./ModalRootComponent"
import ModalRootComponent2 from "./ModalRootComponent copy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";




let SurveyModal = ({ dashboard }) => {
    let [closed, setClosed] = useState(false);
    return <ModalRootComponent2
        children={
            <>
                {!dashboard ?
                    <>
                        {!closed ?
                            <div className=" flex fixed shadow-xl gap-4 top-12 md:top-2 bg-green-500 text-center  items-center justify-center py-2 px-2 rounded-md">

                                <p className="font-thin hidden  md:flex flex-col md:flex-row gap-2 text-white">Login, <Link to={"https://forms.gle/jMdhrfsE6et63DrP7"} ><span className="font-bold text-underline underline underline-offset-1">share your experience</span></Link> on using Boost and also stand a chance to win <span className="font-bold text-white">{Constants.nairaString}20,000</span>
                                    <br></br>
                                    <div className="flex flex-row justify-center items-center">
                                        <Link to={"https://forms.gle/jMdhrfsE6et63DrP7"}>
                                            <p className="bg-white animate-pulse rounded-md text-black text-xs px-2 py-1 ">Feedback form</p>
                                        </Link>
                                    </div>

                                </p>
                                <div className="flex flex-col md:hidden  ">
                                    <p className="flex flex-row font-thin text-xs">
                                        <span>Login,</span>
                                        <span>
                                            <Link to={"https://forms.gle/jMdhrfsE6et63DrP7"} >
                                                <span className="font-bold text-underline underline underline-offset-1">share your experience</span>
                                            </Link>
                                            on using Boost and also stand a chance to win
                                            <span className="font-bold text-white ">
                                                {Constants.nairaString}20,000
                                                &nbsp;&nbsp;
                                                <Link to={"https://forms.gle/jMdhrfsE6et63DrP7"}>
                                                    <span className="bg-white animate-pulse rounded-md text-black text-xs px-2 py-1 mt-2">Feedback form</span>
                                                </Link>
                                            </span>

                                        </span>

                                    </p>

                                </div>

                                <FontAwesomeIcon onClick={() => setClosed(true)} className="text-sm hover:cursor-pointer hover:text-md text-right" icon={faClose}></FontAwesomeIcon>
                            </div>
                            : ""
                        }
                    </>
                    :
                    <>{!closed ?
                        <div className=" flex fixed shadow-xl gap-4 top-12 md:top-2 bg-green-500 text-center  items-center justify-center py-2 px-2 rounded-md">

                            <p className="font-thin hidden  md:flex flex-col md:flex-row gap-2 text-white">
                                Having a great time ? , <Link to={"https://forms.gle/jMdhrfsE6et63DrP7"} ><span className="font-bold text-underline underline underline-offset-1">share your experience</span></Link> on using Boost and also stand a chance to win <span className="font-bold text-white">{Constants.nairaString}20,000</span>
                                <br></br>
                                <div className="flex flex-row justify-center items-center">
                                    <Link to={"https://forms.gle/jMdhrfsE6et63DrP7"}>
                                        <p className="bg-white animate-pulse rounded-md text-black text-xs px-2 py-1 ">Feedback form</p>
                                    </Link>
                                </div>

                            </p>
                            <div className="flex flex-col md:hidden text-white ">
                                <p className="flex flex-row font-thin text-xs">
                                    <span>Having a great time?
                                        &nbsp;
                                        <Link to={"https://forms.gle/jMdhrfsE6et63DrP7"} >
                                            <span className="font-bold text-underline underline underline-offset-1">share your experience</span>
                                        </Link>
                                        &nbsp;
                                        on using Boost and also stand a chance to win
                                        &nbsp;
                                        <span className="font-bold text-white ">
                                            {Constants.nairaString}20,000
                                            &nbsp;&nbsp;
                                            <br></br>
                                            <Link to={"https://forms.gle/jMdhrfsE6et63DrP7"}>
                                                <span className="bg-white animate-pulse rounded-md text-black text-xs px-2 py-1 mt-10">Feedback form</span>
                                            </Link>
                                        </span>

                                    </span>

                                </p>

                            </div>
                            <FontAwesomeIcon onClick={() => setClosed(true)} className="text-sm hover:text-md text-right" icon={faClose}></FontAwesomeIcon>
                        </div>
                        : ""
                    }
                    </>
                }
            </>
        }
    ></ModalRootComponent2 >
}

export default SurveyModal;