import { Link } from "react-router-dom";
import ModalRootComponent from "./modals/ModalRootComponent";


let SurveyModalComponent = ({ logout, setIsModalActive }) => {

    return <ModalRootComponent
        children={
            <div className="z20 font-lota lota-font rounded-2xl self-center p-6 pt-9 bg-white">
                <div onClick={() => setIsModalActive(false)} className="hover:cursor-pointer text-rouzo-text-color-2 font-medium text-xl">
                    <p>{'x'} close</p>
                </div>
                <div className="flex flex-col gap-6">
                    <p>Can you help filling a short survey on your Boost experience and stand a chance to win up to 20,000 Naira</p>
                    <div className="flex flex-row gap-2 justify-center">
                        <Link to={"https://forms.gle/jMdhrfsE6et63DrP7"}>
                            <p className="rounded-md bg-green-400 px-10 py-1 text-white hover:shadow-md">Yes</p>
                        </Link>
                        <p onClick={() => logout(true)} className="rounded-md bg-red-400 px-2 py-1 hover:shadow-md ">No, proceed to logout</p>
                    </div>
                </div>
            </div>
        }
    >

    </ModalRootComponent>
}

export default SurveyModalComponent;