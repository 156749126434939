import { useContext, useEffect, useRef, useState } from "react";
import InputComponent from "../../components/InputComponent";
import useAccounts from "../../hooks/useAccount";
import { AccountContext } from "../../context/AccountContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";


let SettingsPage = () => {


    let { saveSettings, newSettings, fetchSettings } = useAccounts();
    let { settings } = useContext(AccountContext);
    let [loyaltyRewardsType, setLoyaltyRewardsType] = useState("points");
    let [loyaltyRewardPointsPerTransaction, setLoyaltyRewardPointsPerTransaction] = useState(null);
    let [redeemableBandForPoints, setRedeemableband] = useState(null);
    let [pointValueInNaira, setPointValueInNaira] = useState(null);
    let [referralDiscountReward, setReferralDiscountReward] = useState(null);


    const loyaltyRewardPointsPerTransactionRef = useRef(null);
    const pointValueInNairaRef = useRef(null);
    const redeemableBandForPointsRef = useRef(null);
    const referralDiscountRewardRef = useRef(null);

    let [isLoading, setIsLoading] = useState(false);

    let handleSettingsChange = async (event) => {
        const { target } = event;
        console.log(event);
        console.log(loyaltyRewardPointsPerTransactionRef)
        if (loyaltyRewardPointsPerTransactionRef.current) {
            console.log("LR: ",loyaltyRewardPointsPerTransactionRef.current.value)
            setLoyaltyRewardPointsPerTransaction(loyaltyRewardPointsPerTransactionRef.current?.value);
        }
        if (pointValueInNairaRef.current) {
            setPointValueInNaira(pointValueInNairaRef.current?.value);
        }
        if (redeemableBandForPointsRef.current) {
            setRedeemableband(redeemableBandForPointsRef.current?.value);
        }
        if (referralDiscountRewardRef.current) {
            setReferralDiscountReward(referralDiscountRewardRef.current?.value)
        }
    }

    useEffect(() => {
        if (loyaltyRewardPointsPerTransactionRef.current) {
            loyaltyRewardPointsPerTransactionRef.current.value = loyaltyRewardPointsPerTransaction ? loyaltyRewardPointsPerTransaction : "";
        }
        if (pointValueInNairaRef.current) {
            pointValueInNairaRef.current.value = pointValueInNaira ? pointValueInNaira : "";
        }
        if (redeemableBandForPointsRef.current) {
            redeemableBandForPointsRef.current.value = redeemableBandForPoints ? redeemableBandForPoints : "";
        }
        if (referralDiscountRewardRef.current) {
            referralDiscountRewardRef.current.value = referralDiscountReward ? referralDiscountReward : "";
        }
    }, [
        referralDiscountReward,
        loyaltyRewardPointsPerTransaction,
        pointValueInNaira,
        redeemableBandForPoints
    ])

    let handleNewSettings = async () => {
        if (isLoading) {
            return;
        }

        setIsLoading(true);
        try {
            let ns = await newSettings({
                loyaltyRewardsType: loyaltyRewardsType,
                loyaltyRewardPointsPerTransaction: loyaltyRewardPointsPerTransaction ? loyaltyRewardPointsPerTransaction : 1,
                pointValueInNaira: pointValueInNaira ? pointValueInNaira : 1,
                redeemableBandForPoints: redeemableBandForPoints ? redeemableBandForPoints : 1,
                referralDiscountReward: referralDiscountReward ? referralDiscountReward : 1
            });
            if (ns.success) {
                await fetchSettings();
            }
        } catch (e) {

        }
        setIsLoading(false);
    }
    let handleSaveSettings = async () => {
        if (isLoading) {
            return;
        }

        setIsLoading(true);
        try {
            let ss = await saveSettings({
                loyaltyRewardsType: loyaltyRewardsType,
                loyaltyRewardPointsPerTransaction: loyaltyRewardPointsPerTransaction,
                pointValueInNaira: pointValueInNaira,
                redeemableBandForPoints: redeemableBandForPoints,
                referralDiscountReward: referralDiscountReward
            });
            if (ss.success) {
                fetchSettings();
            }
        }
        catch (e) {
            console.log(e)
        }
        setIsLoading(false);
    }


    return <>
        <div className="rounded-md flex flex-col gap-4">

            <div className="flex flex-col text-left text-xs bg-slate-50 gap-4 rounded-md p-4 pt-2">

                <p className="font-regular underline underline-offset-1 text-md">Loyalty Rewards</p>
                <div className="flex flex-col">
                    <div className="flex flex-row gap-2 items-center">
                        <p className="font-bold text-md">Rewards Type</p>
                        <select className="rounded-md bg-slate-100 px-2 py-1 text-md ">
                            <option className="text-md" value="points">Points</option>
                        </select>
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="flex flex-row gap-2 items-center">
                        <p className="font-bold text-md">Reward Points Per Transaction</p>
                        {
                            settings ?
                                <InputComponent ref={loyaltyRewardPointsPerTransactionRef} onChange={handleSettingsChange} className="bg-slate-200" placeholder="points"></InputComponent>
                                :
                                <InputComponent ref={loyaltyRewardPointsPerTransactionRef} onChange={handleSettingsChange} className="bg-slate-200" placeholder="points"></InputComponent>
                        }

                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="flex flex-row gap-2 items-center">
                        <p className="font-bold text-md"> Point Value in Naira</p>
                        {
                            settings ?
                                <InputComponent ref={pointValueInNairaRef} onChange={handleSettingsChange} className="bg-slate-200" placeholder="points value"></InputComponent>
                                :
                                <InputComponent ref={pointValueInNairaRef} onChange={handleSettingsChange} className="bg-slate-200" placeholder="points"></InputComponent>
                        }
                        {settings ?
                            <p>

                            </p>
                            :
                            ""}

                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="flex flex-row gap-2 items-center">
                        <p className="font-bold text-md"> Minimum Redeemable Point</p>
                        {
                            settings ?
                                <InputComponent ref={redeemableBandForPointsRef} onChange={handleSettingsChange} className="bg-slate-200" placeholder="miminimum band for redeemable point"></InputComponent>
                                :
                                <InputComponent ref={redeemableBandForPointsRef} onChange={handleSettingsChange} className="bg-slate-200" placeholder="points"></InputComponent>
                        }
                        {settings ?
                            <p>

                            </p>
                            :
                            ""}

                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="flex flex-row gap-2 items-center">
                        <p className="font-bold text-md flex flex-row gap-1">
                            <p>Referral Discount Reward (in %)</p>
                        </p>
                        {
                            settings ?
                                <InputComponent ref={referralDiscountRewardRef} onChange={handleSettingsChange} className="bg-slate-200" placeholder="discount code reward for referrals"></InputComponent>
                                :
                                <InputComponent ref={referralDiscountRewardRef} onChange={handleSettingsChange} className="bg-slate-200" placeholder="percentage"></InputComponent>
                        }
                        {settings ?
                            <p>

                            </p>
                            :
                            ""}

                    </div>
                </div>
                <div className="flex flex-row justify-end">
                    {settings ?
                        <p onClick={() => handleSaveSettings()} className={`${isLoading ? "bg-slate-300 text-black" : "bg-rouzo-deep-blue text-white"} hover:cursor-pointer hover:bg-white hover:text-rouzo-deep-blue rounded-md px-2 py-1 font-bold flex flex-row gap-2 items-center`}>
                            {isLoading ? <p className="font-regular">Saving .. </p> : <p>Save</p>}
                            {isLoading ?
                                <FontAwesomeIcon className="h-4" icon={faSpinner}></FontAwesomeIcon>
                                :
                                ""}
                        </p>
                        :
                        <p onClick={() => handleNewSettings()} className={`${isLoading ? "bg-slate-300 text-black" : "bg-rouzo-deep-blue text-white"} hover:cursor-pointer hover:bg-white hover:text-rouzo-deep-blue rounded-md px-2 py-1 font-bold flex flex-row gap-2 items-center`}>
                            {isLoading ? <p className="font-regular">Creating settings </p> : <p>Create New</p>}
                            {isLoading ?
                                <FontAwesomeIcon spin={true} className="h-4 text-rouzo-green-light " icon={faSpinner}></FontAwesomeIcon>
                                :
                                ""}
                        </p>
                    }
                </div>

            </div>


        </div>
    </>
}

export default SettingsPage;