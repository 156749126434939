import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import AuthService from "../../api/services/AuthService";
import Logo from "../../assets/img/logo.svg"
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

let EmailVerificationPage = () => {
    let { emailAddress, verificationAuthCode } = useParams();
    let [isLoading, setIsLoading] = useState(false);
    let [ranVerificationProcess, setRanVerificationProcess] = useState(false);
    const navigate = useNavigate();

    let handleVerify = async () => {
        let ver = await AuthService.verifyEmail(emailAddress, verificationAuthCode);

        setTimeout(() => {
            console.log(ver);
            setRanVerificationProcess(true);
            if (ver.success) {
                navigate(`/verify/success/${ver.msg}`);
            }
            else {
                navigate(`/verify/failed/${emailAddress}`);
            }
        }, 2000)

    }

    useEffect(() => {

        if (!ranVerificationProcess) {
            if (!emailAddress || !verificationAuthCode) {
                toast.error("Invalid fields");
                navigate("/login")
            }
            if (emailAddress && verificationAuthCode) {
                handleVerify();
            }

        }

    }, [])

    return <>
        <div className="flex h-screen justify-center flex-col items-center lota-font font-lota">
            <div className="flex flex-col bg-rouzo-overlay shadow-2xl rounded-2xl p-8  items-center">
                <img className="h-24" src={Logo}></img>
                <p className="">Hold on we are verifying your email.</p>
                {!ranVerificationProcess ? <FontAwesomeIcon className="h-4 text-rouzo-base-color" spinPulse={true} icon={faSpinner} spin /> : ""}
            </div>
        </div>
    </>
}

export default EmailVerificationPage;