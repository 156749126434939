
import { AccountContext } from "../../context/AccountContext";
import TransactionService from "../../api/services/TransactionService";
import ModalRootComponent from "./ModalRootComponent";
import CTAButtonComponent from "../CTAButtonComponent";
import InputComponent from "../InputComponent";
import { toast } from "react-toastify";
import { useState, useContext, useEffect } from "react";
import useAccounts from "../../hooks/useAccount";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faDownload, faInfo, faInfoCircle, faLink, faSpinner, faUpload, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import PaymentLinkService from "../../api/services/PaymentLinkService";
import AccountService from "../../api/services/AccountService";
import axios from "../../api/axios";
import downloadAxios from "../../api/downloadAxios";
import RequestService from "../../api/services/RequestService";

let NewCustomerUploadComponent = ({ setIsModalActive }) => {
    const navigate = useNavigate();

    let [fullName, setFullName] = useState(0);
    let [mobileNumber, setMobileNumber] = useState(null);
    let [emailAddress, setEmailAddress] = useState(null);
    let [fileSelected, setFileSelected] = useState(null);
    let [fileBase64, setFileBase64] = useState(null);
    let [isUploading, setIsUploading] = useState(false);

    let { fetchCustomers } = useAccounts();

    let [errorMsg, setErrorMsg] = useState("");

    let [isCreatingCustomer, setIsCreatingCustomer] = useState(false);
    let [generatedCustomer, setGeneratedCustomer] = useState(null);

    useEffect(() => {


    })

    let handleSampleDownload = async () => {


        let downloadUrl = `${downloadAxios}/download/boost_customers_sample.xlsx`;

        const downloadLink = document.querySelector('.download-link')
        downloadLink.href = downloadUrl // Prevent cache
        downloadLink.click()
    }

    let handleNewCustomerCreation = async () => {
        if (isCreatingCustomer) {
            return;
        }
        setIsCreatingCustomer(true);
        if (!fullName) {

            setIsCreatingCustomer(false);
            toast.error("Invalid full name");
            return
        }
        if (!mobileNumber) {
            toast.error("You need to add a mobile number");
            setIsCreatingCustomer(false);
            return
        }
        if (!emailAddress) {
            toast.error("You need to add a valid emailAddress");
            setIsCreatingCustomer(false);
            return
        }
        try {
            let newCustomer = await AccountService.addCustomer(
                {
                    fullName: fullName,
                    mobileNumber: mobileNumber,
                    emailAddress: emailAddress
                }
            );

            if (newCustomer.success) {
                toast.success(newCustomer.msg);
                fetchCustomers();
                console.log(newCustomer);
            }
            else {
                toast.error(newCustomer.msg)
            }
        }
        catch (e) {
            console.log(e);
        }

        setIsCreatingCustomer(false)


    }
    let hFileSelect = () => {
        let fu = document.querySelector(".customerFileUpload");
        fu.click();

    }

    let handleFileUpload = async () => {
        if (isUploading) {
            return;
        }
        if(fileSelected == null){
            return
        }
        setIsUploading(true);
        let base64string = document.getElementById('base64').value;

        console.log(base64string);

        let req = await AccountService.uploadCustomers({
            file: fileBase64
        });

        if (req.success) {
            toast.success(req.msg);
            fetchCustomers()
        }
        else {
            toast.error(req.msg);
        }

        setIsUploading(false);

    }

    let fileCheck = async (e) => {
        console.log(fileCheck)
        console.log(e)
        console.log(e.target.value.split("\\"));
        var ext = e.target.value.match(/\.([^\.]+)$/)[1];
        switch (ext) {
            case 'xlsx':
            case 'xls':
                let loc = e.target.value.split("\\");
                setFileSelected(loc[loc.length - 1]);
                handleFileSelect(e);
                break;
            default:
                alert('File type not allowed');
                e.target.value = '';

                return;
        }



    }

    let handleFileSelect = async (evt) => {
        console.log("Conversion+++++++++++++++++++");
        var f = evt.target.files[0]; // FileList object
        var reader = new FileReader();
        // Closure to capture the file information.
        reader.onload = (function (theFile) {
            return function (e) {
                var binaryData = e.target.result;
                //Converting Binary Data to base 64
                var base64String = window.btoa(binaryData);
                //showing file converted to base64
                document.getElementById('base64').value = base64String;
                setFileBase64(base64String)
                //alert('File converted to base64 successfuly!\nCheck in Textarea');
            };
        })(f);
        // Read in the image file as a data URL.
        reader.readAsBinaryString(f);

        console.log(reader)
        console.log("Conversion end +++++++++++++++++++ \n");
    }
    return <ModalRootComponent
        children={
            <div className="z20 font-lota flex flex-col lota-font rounded-t-md border border-t-2 border-t-rouzo-green-light self-center bg-white">
                <div className="flex flex-row justify-end text-right p-1 pb-0 hover:cursor-pointer hover:text-xl">
                    <p onClick={() => setIsModalActive(false)} className="">
                        <FontAwesomeIcon className="text-md" icon={faClose}></FontAwesomeIcon>
                    </p>
                </div>
                <div className="flex flex-col p-6 pt-1 gap-2">
                    <div className="text-center">
                        <p className="font-bold text-md text-rouzo-green-light">Import Customer</p>
                    </div>
                    <div>
                        <div className="flex flex-col gap-2">
                            <p className="flex flex-row gap-2 items-center">
                                <FontAwesomeIcon icon={faInfoCircle} className="text-rouzo-base-color">

                                </FontAwesomeIcon>
                                <p>Ensure you download the customer sample sheet.</p>
                            </p>
                            <div className="flex flex-row justify-center">
                                <p onClick={() => handleSampleDownload()} className="flex flex-row gap-1 p-1 items-center text-xs font-bold hover:cursor-pointer justify-center bg-rouzo-green-light rounded-sm text-white font-bold">
                                    <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
                                    Download customer sample sheet
                                </p>
                            </div>
                            <a className="download-link"></a>
                            <div onClick={() => hFileSelect()} className="hover:cursor-pointer outline-dashed rounded-md outline-slate-400 outline-2 p-6 justify-center">
                                <p>
                                    <FontAwesomeIcon icon={faUpload} className="h-10 text-slate-400"  ></FontAwesomeIcon>
                                </p>
                                <p className="text-rouzo-green-light/80">Upload your customers sheet</p>
                                <input onChange={(e) => fileCheck(e)} accept=".xls,.xlsx" onSelect={(e) => handleFileSelect(e)} className="customerFileUpload" type="file" hidden></input>
                                <input id="base64" className="hidden"></input>
                                <p className="border text-xs font-bold border-1 border-slate-300 rounded-md 00 px-2">
                                    {fileSelected}
                                </p>
                            </div>
                            <div className="flex flex-row text-center justify-center">
                                <p onClick={() => handleFileUpload()} className={`${fileSelected != null ? "bg-rouzo-green-light text-white" : "bg-slate-400 text-black"} rounded-sm px-1 flex flex-row items-center gap-2 hover:cursor-pointer  px-2 text-center`}>
                                    {isUploading ? "Processing Upload" : "Process Upload"}
                                    {isUploading ?
                                        <FontAwesomeIcon icon={faSpinner} spin={true} className="text-white h-4"></FontAwesomeIcon>
                                        :
                                        ""
                                    }
                                    
                                </p>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        }
    >

    </ModalRootComponent>
}


export default NewCustomerUploadComponent;