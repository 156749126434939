
import { motion } from "framer-motion";

let ModalRootComponent = ({children}) => {
    return <>
        <motion.div
            className="z-20 flex absolute justify-center w-screen h-screen bg-rouzo-overlay items-center"
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
        >
            {children}
            
        </motion.div>
    </>
}

export default ModalRootComponent;