
import Constants from "../../constants/Constants";
import axios from "../axios";
// import { checkToken } from "../utils/Utils";
import authHeader from "./auth-header";

let DiscountCodeService = {
   
    resolveDiscountCode: async (paymentLinkId, discountCode) => {
        let url = Constants.PAYMENT_LINK.RESOLVE_DISCOUNT_CODE(paymentLinkId, discountCode);
        let req = await axios.get(url, {
            headers: {
              
            }
        });
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }

        return req.data;
    },
    fetchDiscountCode: async (discountId) => {
        let url = Constants.DISCOUNT_CODES.FETCH_ONE(discountId);
        let req = await axios.get(url, {
            headers: {
                Authorization: `${authHeader()}`
            }
        });
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }

        return req.data;
    },
    fetchDiscountCodes: async () => {
        let url = Constants.DISCOUNT_CODES.FETCH_ALL;
        let req = await axios.get(url, {
            headers: {
                Authorization: `${authHeader()}`
            }
        });
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }

        return req.data;
    },

    new: async (description, percentage, emailAddress, customerId,customCode, customerList,paymentLinkId,_emailAddress) => {
        let url = Constants.DISCOUNT_CODES.NEW;
        let req = await axios.post(url, {
            description: description,
            percentage: percentage,
            customerId: customerId,
            emailAddress : emailAddress ? emailAddress : (_emailAddress ? emailAddress : ""),
            customCode : customCode,
            customerList : customerList,
            paymentLinkId : paymentLinkId
        }, {
            headers: {
                Authorization: `${authHeader()}`
            }
        });
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }

        return req.data;
    },
}

export default DiscountCodeService