import CTAButtonComponent from "../CTAButtonComponent";
import ModalRootComponent from "./ModalRootComponent";


let PromoModal = ({ setActive }) => {
    return <ModalRootComponent
        children={
            <div className="z20 font-lota lota-font rounded-2xl self-center p-6 pt-9 bg-white">
                <div onClick={() => setActive(false)} className="hover:cursor-pointer text-rouzo-text-color-2 font-medium text-xl">
                    <p>{'x'} close</p>
                </div>
                <div className="mb-6">
                    <p className="text-rouzo-text-color-2 font-bold text-2xl mb-6">Hi, <br></br>hope you had a great experience with Boost</p>
                    <div>
                        <p className="text-rouzo-text-color-2 font-medium text-[24px] mb-6">Join Boost and Turn one time transactions into life time customers
                            <p className="text-xs ">Loyalty Rewards * Discount Coupons * Campaigns * Payment Links</p></p>
                    </div>
                </div>
                <div className="text-right flex justify-end ">
                    <div >
                        <CTAButtonComponent text={"Join Boost"}></CTAButtonComponent>

                    </div>
                </div>
            </div>
        }
    ></ModalRootComponent>
}

export default PromoModal;