import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom"
import PaymentLinkService from "../../api/services/PaymentLinkService";
import Constants from "../../constants/Constants";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight, faCheckSquare, faLock, faPlugCircleCheck, faSpinner, faXmarkSquare } from "@fortawesome/free-solid-svg-icons";
import InputComponent from "../../components/InputComponent";
import LogoImg from "../../assets/img/logo.svg";

let PaymentLinkStatusPage = () => {

    let { paymentLinkId, reference } = useParams();

    let [amount, setAmount] = useState(null);
    let [isLoading, setIsLoading] = useState(false);
    let [isResolvingLink, setIsResolvingLink] = useState(false);
    let [resolvedLink, setResolvedLink] = useState(false);

    let [isStatusSuccess, setStatusSuccess] = useState(null);
    let [isStatusPending, setStatusPending] = useState(null);
    let [isStatusFailed, setStatusFailed] = useState(null);



    let getStatus = async () => {
        if (isResolvingLink) {
            return;
        }
        setIsResolvingLink(true);
        let linkDetails = await PaymentLinkService.fetchPaymentStatus(paymentLinkId, reference);
        if (linkDetails.success) {
            setAmount(linkDetails.data.amount / 100);
            
            setIsResolvingLink(false);
            setResolvedLink(true);
            let {status} = linkDetails.data;
            if(status === "success"){
                //setStatusFailed(true);
                setStatusSuccess(true);
                setTimeout(() => {
                    window.location = "/promo"
                },2000)
            }
            else if(status === "failed"){
                setStatusFailed(true);
            }
            else if(status === "pending"){
                setStatusPending(true);
            }
        }
        else if (linkDetails.msg.includes("invalid")) {
            toast.error(linkDetails.msg + "\n Redirecting..");
            setResolvedLink(true)
            setTimeout(() => {
                window.location = "/"
            }, 2000)

        }
        else {
            toast.error(linkDetails.msg)
            setIsResolvingLink(false);
            setResolvedLink(true)
        }
    }

    useEffect(() => {
        if (paymentLinkId && reference && !resolvedLink) {
            getStatus()
        }
    })



    return <>
        <div className="flex h-screen w-screen items-center justify-center flex-row px-8">
            <div className="self-center p-4 flex pb-8 flex-col gap-4 rounded-md rounded-b-xl bg-slate-50 outline-b-dotted outline-2 outline-slate-500 border-t-[20px] border-t-rouzo-green-light">
                <div className="flex flex-col gap-8 ">
                    <div className="flex flex-col gap-2 items-end">
                        <p className="bg-rouzo-green-light font-bold px-2 text-white text-xs rounded-md">{amount ? `${Constants.nairaString} ${amount}` : "Loading.."}</p>
                        <p className="rounded-md bg-slate-100 text-black-400 gap-2 text-xs px-2  ">{paymentLinkId}</p>
                        <p className="rounded-md bg-slate-100 text-black-400 gap-2 text-xs px-2  ">Reference : <span className="font-bold"> {reference}</span></p>

                    </div>

                    {isResolvingLink ?
                        <div className="">
                            <FontAwesomeIcon className="" icon={faSpinner} spin={true}></FontAwesomeIcon>
                            <p className="font-regular">Verifying your payment...</p>
                        </div>
                        :
                        ""
                    }
                    {
                        resolvedLink && isStatusSuccess ? 
                        <div className="flex flex-col">
                            <div className="flex flex-col">
                                <FontAwesomeIcon className="text-rouzo-green-light h-8" icon={faCheckSquare}></FontAwesomeIcon>
                                <p className="text-rouzo-green-light font-regular">Payment verified successfully</p>
                            </div>
                        </div>
                        :
                        ""
                    }
                    {
                        resolvedLink && isStatusFailed ? 
                        <div className="flex flex-col">
                            <div className="flex flex-col">
                                <FontAwesomeIcon className="text-red-400 h-8" icon={faXmarkSquare}></FontAwesomeIcon>
                                <p className="font-regular text-red-400">Payment failed</p>
                                <Link to={`/paylink/${paymentLinkId}`}>
                                    <p className="underline text-rouzo-base-color">Retry payment</p>
                                </Link>
                            </div>
                        </div>
                        :
                        ""
                    }
                    {
                        resolvedLink && isStatusPending ? 
                        <div className="flex flex-col">
                            <div className="flex flex-col">
                                <FontAwesomeIcon className="text-orange-400" icon={faPlugCircleCheck}></FontAwesomeIcon>
                                <p>Payment is pending</p>
                            </div>
                        </div>
                        :
                        ""
                    }
                    {/*<hr></hr>*/}
                </div>
            </div>

        </div>
    </>
}


export default PaymentLinkStatusPage