
import { AccountContext } from "../../context/AccountContext";
import TransactionService from "../../api/services/TransactionService";
import ModalRootComponent from "./ModalRootComponent";
import CTAButtonComponent from "../CTAButtonComponent";
import InputComponent from "../InputComponent";
import { toast } from "react-toastify";
import { useState, useContext, useEffect } from "react";
import useAccounts from "../../hooks/useAccount";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faLink, faSpinner, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import PaymentLinkService from "../../api/services/PaymentLinkService";
import AccountService from "../../api/services/AccountService";

let NewCustomerComponent = ({ setIsModalActive }) => {
    const navigate = useNavigate();

    let [fullName, setFullName] = useState(0);
    let [mobileNumber, setMobileNumber] = useState(null);
    let [emailAddress, setEmailAddress] = useState(null)

    let { fetchCustomers } = useAccounts();

    let [errorMsg, setErrorMsg] = useState("");

    let [isCreatingCustomer, setIsCreatingCustomer] = useState(false);
    let [generatedCustomer, setGeneratedCustomer] = useState(null);

    useEffect(() => {


    })

    let handleNewCustomerCreation = async () => {
        if (isCreatingCustomer) {
            return;
        }
        setIsCreatingCustomer(true);
        if (!fullName) {

            setIsCreatingCustomer(false);
            toast.error("Invalid full name");
            return
        }
        if (!mobileNumber) {
            toast.error("You need to add a mobile number");
            setIsCreatingCustomer(false);
            return
        }
        if (!emailAddress) {
            toast.error("You need to add a valid emailAddress");
            setIsCreatingCustomer(false);
            return
        }
        try {
            let newCustomer = await AccountService.addCustomer(
                {
                    fullName: fullName,
                    mobileNumber: mobileNumber,
                    emailAddress: emailAddress
                }
            );

            if (newCustomer.success) {
                toast.success(newCustomer.msg);
                fetchCustomers();
                console.log(newCustomer);
            }
            else {
                toast.error(newCustomer.msg)
            }
        }
        catch (e) {
            console.log(e);
        }

        setIsCreatingCustomer(false)


    }
    return <ModalRootComponent
        children={
            <div className="z20 font-lota flex flex-col lota-font rounded-t-md border border-t-2 border-t-rouzo-green-light self-center bg-white">
                <div className="flex flex-row justify-end text-right p-1 pb-0 hover:cursor-pointer hover:text-xl">
                    <p onClick={() => setIsModalActive(false)} className="">
                        <FontAwesomeIcon className="text-md" icon={faClose}></FontAwesomeIcon>
                    </p>
                </div>
                <div className="flex flex-col p-6 pt-1 gap-2">
                    <div className="text-center">
                        <p className="font-bold text-md text-rouzo-green-light">New Customer</p>
                    </div>
                    <div className="flex flex-col md:flex-row gap-2 items-end">
                        <div className="flex flex-col">
                            <p className="text-xs text-left">Full name</p>
                            {isCreatingCustomer ? <InputComponent disabled={true} value={fullName} ></InputComponent> : <InputComponent onChange={(e) => setFullName(e.target.value)}></InputComponent>}
                        </div>
                        <div className="flex flex-col">
                            <p className="text-xs text-left">Mobile number</p>
                            {isCreatingCustomer ? <InputComponent disabled={true} value={mobileNumber}></InputComponent> : <InputComponent type="number" onChange={(e) => setMobileNumber(e.target.value)}></InputComponent>}
                        </div>
                        <div className="flex flex-col">
                            <p className="text-xs text-left">Email address</p>
                            {isCreatingCustomer ? <InputComponent disabled={true} value={emailAddress}></InputComponent> : <InputComponent type="email" onChange={(e) => setEmailAddress(e.target.value)}></InputComponent>}
                        </div>
                        <div onClick={() => handleNewCustomerCreation()} className={`flex flex-row text-white rounded-md p-2 gap-2 ${isCreatingCustomer ? "bg-green-800" : "bg-rouzo-green-light  hover:border-2 hover:cursor-pointer hover:border-rouzo-green-light hover:text-rouzo-green-light hover:bg-white"}`}>
                            {isCreatingCustomer ? <FontAwesomeIcon spin={true} icon={faSpinner}></FontAwesomeIcon> : <FontAwesomeIcon icon={faUserPlus}></FontAwesomeIcon>}
                            <p className="text-xs text-left font-bold ">{isCreatingCustomer ? "Adding customer.." : "Add Customer"}</p>

                        </div>
                        <div className="">

                        </div>
                    </div>
                </div>

            </div>
        }
    >

    </ModalRootComponent>
}


export default NewCustomerComponent;