import { Outlet } from "react-router-dom";
import AccountProvider from "../context/AccountContext";
import { ToastContainer } from "react-toastify";

const AccountLayout = ({children}) => {
    return (
        <main className="">
            {children ? children : <AccountProvider>
                <ToastContainer/>
                <Outlet />
            </AccountProvider>}
        </main>
    )
}

export default AccountLayout