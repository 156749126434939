import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


let TinyErrorComponent = ({msg}) => {
    return <>
        <div className="flex flex-row gap-2 items-center p-2">
            <FontAwesomeIcon className="text-red-400 " icon={faInfoCircle}></FontAwesomeIcon>
            <p className="font-bold text-red-400 text-xs">{msg}</p>
        </div>
    </>
}

export default TinyErrorComponent;