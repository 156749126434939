
import { AccountContext } from "../../context/AccountContext";
import TransactionService from "../../api/services/TransactionService";
import ModalRootComponent from "./ModalRootComponent";
import CTAButtonComponent from "../CTAButtonComponent";
import InputComponent from "../InputComponent";
import { toast } from "react-toastify";
import { useState, useContext, useEffect } from "react";
import useAccounts from "../../hooks/useAccount";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faLink, faSpinner } from "@fortawesome/free-solid-svg-icons";
import PaymentLinkService from "../../api/services/PaymentLinkService";

let NewPaymentLinkComponent = ({ setIsModalActive }) => {
    const navigate = useNavigate();

    let [amount, setAmount] = useState(0);
    let [description, setDescription] = useState(null);

    let {fetchPaymentLinks} = useAccounts();

    let [errorMsg, setErrorMsg] = useState("");

    let [isCreatingLink, setIsCreatingLink] = useState(false);
    let [generatedLink, setGeneratedLink] = useState(null);

    useEffect(() => {


    })

    let handleNewLinkCreation = async () => {
        if (isCreatingLink) {
            return;
        }
        setIsCreatingLink(true);
        if (!isNaN(amount)) {
            if (amount < 0) {
                setIsCreatingLink(false);
                toast.error("Invalid amount");
                return;
            }
        }
        if (!description) {
            toast.error("You need to add a description");
            setIsCreatingLink(false);
            return
        }
        try {
            let newlink = await PaymentLinkService.new(
                description,
                amount,""
            );

            if (newlink.success) {
                toast.success(newlink.msg);
                fetchPaymentLinks();
                console.log(newlink);
            }
            else{
                toast.error(newlink.msg)
            }
        }
        catch (e) {
            console.log(e);
        }

        setIsCreatingLink(false)


    }
    return <ModalRootComponent
        children={
            <div className="z20 font-lota flex flex-col lota-font rounded-t-md border border-t-2 border-t-rouzo-green-light self-center bg-white">
                <div className="flex flex-row justify-end text-right p-1 pb-0 hover:cursor-pointer hover:text-xl">
                    <p onClick={() => setIsModalActive(false)} className="">
                        <FontAwesomeIcon className="text-md" icon={faClose}></FontAwesomeIcon>
                    </p>
                </div>
                <div className="flex flex-col p-6 pt-1 gap-2">
                    <div className="text-center">
                        <p className="font-bold text-md text-rouzo-green-light">New Payment Link</p>
                    </div>
                    <div className="flex flex-col md:flex-row gap-2 items-end">
                        <div className="flex flex-col">
                            <p className="text-xs text-left">Description</p>
                            {isCreatingLink ? <InputComponent disabled={true} value={description} ></InputComponent> : <InputComponent onChange={(e) => setDescription(e.target.value)}></InputComponent>}
                        </div>
                        <div className="flex flex-col">
                            <p className="text-xs text-left">Amount</p>
                            {isCreatingLink ? <InputComponent disabled={true} value={amount}></InputComponent> : <InputComponent type="number" onChange={(e) => setAmount(e.target.value)}></InputComponent>}
                        </div>
                        <div onClick={() => handleNewLinkCreation()} className={`flex flex-row text-white rounded-md p-2 gap-2 ${isCreatingLink ? "bg-green-800" : "bg-rouzo-green-light  hover:border-2 hover:cursor-pointer hover:border-rouzo-green-light hover:text-rouzo-green-light hover:bg-white"}`}>
                            {isCreatingLink ? <FontAwesomeIcon spin={true} icon={faSpinner}></FontAwesomeIcon> : <FontAwesomeIcon icon={faLink}></FontAwesomeIcon>}
                            <p className="text-xs text-left font-bold ">{isCreatingLink ? "Creating.." : "Create"}</p>

                        </div>
                        <div className="">

                        </div>
                    </div>
                </div>

            </div>
        }
    >

    </ModalRootComponent>
}


export default NewPaymentLinkComponent;