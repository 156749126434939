import React from "react";


let InputComponent = React.forwardRef((props,ref) => {
    let { placeholder, innerRef, onChange, value } = props;
    
        return <>
            <div>
                <input ref={ref} value={value} onChange={onChange} {...props} placeholder={placeholder} className="p-1 px-4 bg-rouzo-light-blue-2 rounded-md"></input>
            </div>
        </>

    
});
export default InputComponent;