import {Outlet} from "react-router-dom";
import { ToastContainer } from "react-toastify";
const Layout = () => {
    return (
        <main className="App">
            <ToastContainer/>
            <Outlet/>
        </main>
    )
}

export default Layout