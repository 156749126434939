import { useState, React, createContext } from "react";


export const AccountContext = createContext();

// Create an exportable consumer that can be injected into components
export const AccountConsumer = AccountContext.Consumer
// Create the provider using a traditional React.Component class

const AccountProvider = ({children}) => {
    
    let [savingHistory, setSavingHistory] = useState([]);
    let [user, setUser] = useState({});
    let [userId, setUserId] = useState("");
    let [fullName, setFullName] = useState("");
    let [stashBalance , setStashBalance] = useState(0);
    let [savingsBalance , setSavingsBalance] = useState(0);
    let [savingPlans , setSavingPlans] = useState(null);
    let [transactions, setTransactions] = useState(null);
    let [wallet , setWallet] = useState(null);
    let [isStash , setIsStash] = useState(false);
    let [isAllowed, setIsAllowed] = useState(false);
    let [banks, setBanks] = useState(null);
    let [warnedBanks, setWarnedBanks] = useState(false);
    let [miniTransactions, setMiniTransactions] = useState(null)
    let [withdrawals, setWithdrawals] = useState(null);
    let [funds, setFunds] = useState(0);
    let [referralSlug , setReferralSlug] = useState("");
    let [rankings, setRankings] = useState(null);
    let [firstDayRank, setFirstDayRank] = useState(null);
    let [lastDayRank, setLastDayRank] = useState(null)
    let [yourPosition, setYourPosition] = useState(null);
    let [paymentLinks, setPaymentLinks] = useState(null);
    let [customers, setCustomers] = useState(null);
    let [settings, setSettings] = useState(null);
    let [discountCodes, setDiscountCodes] = useState(null);
    //let [paymentLinks, setPaymentLinks] = useState(null);
   
    return (
        // value prop is where we define what values 
        // that are accessible to consumer components
        <AccountContext.Provider value={{
                
                savingHistory, setSavingHistory,
                
                userId, setUserId,
                user, setUser,
                stashBalance, setStashBalance,
               
                miniTransactions, setMiniTransactions,
                transactions, setTransactions,
                withdrawals, setWithdrawals,
            
                isStash, setIsStash,
                isAllowed, setIsAllowed,
                referralSlug , setReferralSlug,
               
                wallet,setWallet,
                fullName,setFullName,
                banks, setBanks,
                warnedBanks, setWarnedBanks,
                lastDayRank, setLastDayRank,
                firstDayRank, setFirstDayRank,
                rankings, setRankings,
                yourPosition, setYourPosition,
                paymentLinks, setPaymentLinks,
                customers, setCustomers,
                settings, setSettings,
                discountCodes, setDiscountCodes
            }}>
            {children}
        </AccountContext.Provider>
    )
}
export default AccountProvider
