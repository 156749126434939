import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Constants from "../constants/Constants"
import { faArrowAltCircleDown, faArrowAltCircleUp, faArrowDownLong, faArrowUpLong, faCopy, faMoneyBill } from "@fortawesome/free-solid-svg-icons"
import wave1 from "../assets/img/waves-001.jpg"


let WithdrawalListComponent = ({ data, useScroll }) => {
    return <>
        <div className={`flex flex-col ${useScroll ? "overflow-y-scroll" : ""}  gap-2 `}>
            {
                data ?
                    data.length > 0 ?

                        data.map((transaction) => {
                            return <div>

                                <div className={`outline  outline-1 rounded-md ${transaction.status == "success" ? "bg-emerald-300/30 outline-emerald-300" : "bg-slate-500 outline-black"}`}>
                                    {/*<div className="flex flex-row justify-end">
                                            <p className="text-xs text-right bg-rouzo-green-light px-2 pt-0 rounded-md text-white font-bold">success</p>
                                        </div>*/}
                                    <div className="flex items-center justify-between flex-row gap-4 px-2 py-4 ">
                                        <div className="flex items-center flex-row gap-4 ">

                                            <FontAwesomeIcon className="text-rouzo-green-light h-4" icon={faArrowAltCircleUp}></FontAwesomeIcon>

                                            <div className="md:flex hidden  flex-col gap-1">
                                                <p className=" flex flex-row gap-1 items-center font-regular text-[9px] bg-slate-50 px-2 rounded-full">
                                                    {transaction.withdrawalId}
                                                    <FontAwesomeIcon icon={faCopy}></FontAwesomeIcon>
                                                </p>
                                                <div className="flex flex-row text-left justify-start">
                                                    {
                                                        transaction.status == "success" ?
                                                            <p className="bg-rouzo-green-light text-white text-[8px] py-0 px-2 rounded-full">{transaction.status ? transaction.status : ""}</p>
                                                            :
                                                            <p className="bg-slate-800 text-white text-[8px] py-0 px-2 rounded-full">{transaction.status ? transaction.status : ""}</p>
                                                    }
                                                </div>

                                            </div>
                                            <div className=" flex flex-col gap-1">
                                                <p className="font-regular text-left text-xs">{transaction.emailAddress ? transaction.emailAddress : ""}</p>
                                                <p className="font-regular text-[9px] text-left rounded-full">{transaction.createdAt ? `${new Date(transaction.createdAt).toDateString()} ${new Date(transaction.createdAt).toLocaleTimeString()}` : ""}</p>
                                                <p className=" items-center truncate font-regular text-[9px] bg-slate-50 px-2 rounded-full">
                                                    {transaction.transactionId}
                                                </p>
                                            </div>


                                        </div>
                                        <div className="flex flex-col gap-1 justify-end">
                                            <p className="font-bold text-rouzo-deep-blue rounded-md bg-white px-2">{Constants.nairaString}{transaction.amount ? transaction.amount : 0}</p>
                                            <div className="flex flex-row text-left md:hidden justify-start">
                                                {
                                                    transaction.status == "success" ?
                                                        <p className="bg-rouzo-green-light text-white text-[8px] py-0 px-2 rounded-full">{transaction.status ? transaction.status : ""}</p>
                                                        :
                                                        <p className="bg-slate-800 text-white text-[8px] py-0 px-2 rounded-full">{transaction.status ? transaction.status : ""}</p>
                                                }
                                            </div>
                                        </div>


                                    </div>


                                </div>

                            </div>
                        })
                        :
                        <div style={{ background: `url('${wave1}')`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "right" }} className="rounded-md">
                            <div className="bg-white p-4 bg-opacity-90">
                                <div className="flex  text-center flex-col rounded-md items-center gap-4">
                                    <div className="flex flex-col items-center gap-2">
                                        <FontAwesomeIcon className="text-3xl text-rouzo-blue-gray-white" icon={faMoneyBill}></FontAwesomeIcon>
                                        <p className="text-sm text-rouzo-text-color-2 font-bold">No withdrawals yet</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    :
                    <div style={{ background: `url('${wave1}')`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "right" }} className="rounded-md">
                        <div className="bg-white p-4 bg-opacity-90">
                            <div className="flex  text-center flex-col rounded-md items-center gap-4">
                                <div className="flex flex-col items-center gap-2">
                                    <FontAwesomeIcon className="text-3xl text-rouzo-blue-gray-white" icon={faMoneyBill}></FontAwesomeIcon>
                                    <p className="text-sm text-rouzo-text-color-2 font-bold">No withdrawals yet</p>
                                </div>
                            </div>
                        </div>

                    </div>
            }

        </div>
    </>
}


export default WithdrawalListComponent