
import Constants from "../../constants/Constants";
import axios from "../axios";
// import { checkToken } from "../utils/Utils";
import authHeader from "./auth-header";

let PaymentLinkService = {
    resolveEmailAddress: async (emailAddress, paymentLinkId) => {
        let url = Constants.PAYMENT_LINK.RESOLVE_EMAIL_ADDRESS(emailAddress,paymentLinkId);
        let req = await axios.get(url, {
            headers: {
              
            }
        });
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }

        return req.data;
    },
    resolveDiscountCode: async (paymentLinkId, discountCode) => {
        let url = Constants.PAYMENT_LINK.RESOLVE_DISCOUNT_CODE(paymentLinkId, discountCode);
        let req = await axios.get(url, {
            headers: {
              
            }
        });
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }

        return req.data;
    },
    fetchProfile: async (auth) => {
        let url = Constants.USER.PROFILE;
        let req = await axios.get(url, {
            headers: {
                Authorization: `${auth}`
            }
        });
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }

        return req.data;
    },
    initiatePayment: async (paymentLinkId, userDetails) => {
        let url = Constants.PAYMENT_LINK.INITIATE_PAYMENT;
        let req = await axios.post(url, {
            ...userDetails,
            paymentLinkId: paymentLinkId
        }, {
            headers: {
                Authorization: `${authHeader()}`
            }
        });
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }

        return req.data;
    },
    fetchPaymentStatus: async (paymentLinkId, reference) => {
        let url = Constants.PAYMENT_LINK.VERIFY_PAYMENT(paymentLinkId, reference);

        let req = await axios.get(url, {
            headers: {
                Authorization: `${authHeader()}`
            }
        });
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }

        return req.data;
    },
    resolveAccount: async (accountNumber, bankCode, bankName) => {
        let url = "/misc/resolveAccount";
        let req = await axios.post(url, {
            accountNumber: accountNumber,
            bankCode: bankCode,
            bankName: bankName
        }, {
            headers: {
                Authorization: `${authHeader()}`
            }
        });
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }

        return req.data;
    },
    fetchPaymentLink: async (paymentLinkId) => {
        let url = Constants.PAYMENT_LINK.GET(paymentLinkId);
        let req = await axios.get(url, {
            headers: {
                Authorization: `${authHeader()}`
            }
        });
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }

        return req.data;
    },
    fetchPaymentLinks: async () => {
        let url = Constants.PAYMENT_LINK.GETALL();
        let req = await axios.get(url, {
            headers: {
                Authorization: `${authHeader()}`
            }
        });
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }

        return req.data;
    },

    new: async (description, amount, customUniqueId) => {
        let url = Constants.PAYMENT_LINK.NEW;
        let req = await axios.post(url, {
            description: description,
            amount: amount,
            customUniqueId: customUniqueId
        }, {
            headers: {
                Authorization: `${authHeader()}`
            }
        });
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }

        return req.data;
    },
    fetchBankList: async (code, auth) => {
        let url = "/misc/banks";
        let req = await axios.get(url, {
            code: code
        }, {
            headers: {
                Authorization: `${authHeader()}`
            }
        });
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }

        return req.data;
    }
}

export default PaymentLinkService