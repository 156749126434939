import Constants from "../../constants/Constants";
import axios from "../axios";
// import { checkToken } from "../utils/Utils";
import authHeader from "./auth-header";
const AccountService = {
    fetchAccount: async (accountId, auth) => {
        let url = Constants.LOGIN.INITIATE;
        let req = await axios.get(url, {
            headers: {
                Authorization: `${auth}`
            }
        });
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }
        return req.data;
    },
    fetchAllAccounts: async (auth) => {
        console.log(auth);
        let url = Constants.ACCOUNTS.ALL;
        let req = await axios.get(url, {
            headers: {
                Authorization: `${auth}`
            }
        });
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }
        return req.data;
    },
    newAccount: async (data) => {
        let url = Constants.ACCOUNTS.NEW;
        console.log(authHeader())
        let req = await axios.post(url, data, {
            headers: {
                Authorization: `${authHeader()}`
            }
        });
        if (req.data) {
            let { type, data } = req.data;
            console.log(data)
        }
        return req.data;
    },
    updateAccount: async (data) => {
        let url = Constants.ACCOUNTS.UPDATE;
        console.log(url)
        let req = await axios.post(url, data, {
            headers: {
                Authorization: `${authHeader()}`
            }
        })

        if (req.data) {
            let { type, data } = req.data;
            console.log(data);
        }
        return req.data;
    },
    
    uploadCustomers: async (data) => {
        let url = Constants.USER.UPLOAD_CUSTOMERS;
        console.log(url)
        let req = await axios.post(url, data, {
            headers: {
                Authorization: `${authHeader()}`
            }
        })

        if (req.data) {
            let { type, data } = req.data;
            console.log(data);
        }
        return req.data;
    },
    fetchCustomers: async () => {
        let url = Constants.USER.FETCH_CUSTOMERS;
        let req = await axios.get(url, {
            headers: {
                Authorization: `${authHeader()}`
            }
        });
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }
        return req.data;
    },
    addCustomer : async (data) => {
        let url = Constants.USER.ADD_CUSTOMER;
        console.log(url)
        let req = await axios.post(url, data, {
            headers: {
                Authorization: `${authHeader()}`
            }
        })

        if (req.data) {
            let { type, data } = req.data;
            console.log(data);
        }
        return req.data;
    },
    fetchWithdrawals: async () => {
        let url = Constants.USER.WITHDRAWALS;
        let req = await axios.get(url, {
            headers: {
                Authorization: `${authHeader()}`
            }
        });
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }
        return req.data;
    },
    fetchSettings: async () => {
        let url = Constants.SETTINGS.FETCH;
        let req = await axios.get(url, {
            headers: {
                Authorization: `${authHeader()}`
            }
        });
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }
        return req.data;
    },
    newSettings: async (settings) => {
        let url = Constants.SETTINGS.NEW;
        let req = await axios.post(url, settings,{
            headers: {
                Authorization: `${authHeader()}`
            }
        });
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }
        return req.data;
    },
    saveSettings: async (settings) => {
        let url = Constants.SETTINGS.SAVE;
        let req = await axios.put(url,settings, {
            headers: {
                Authorization: `${authHeader()}`
            }
        });
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }
        return req.data;
    },
    fetchTransactions: async () => {
        let url = Constants.USER.TRANSACTIONS;
        let req = await axios.get(url, {
            headers: {
                Authorization: `${authHeader()}`
            }
        });
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }
        return req.data;
    }
};

export default AccountService;
