import TopNavComponent from "../../components/home/TopNavComponent";


import UserSvg from "../../assets/user-svgrepo-com.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowRight, faArrowUp, faEnvelope, faLocation, faNavicon, faPhone } from "@fortawesome/free-solid-svg-icons";
import HomePageSidebarComponent from "../../components/HomePageSidebarComponent";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";

import PromoModal from "../../components/modals/PromoModal";
import SurveyModal from "../../components/modals/SurveyModal";
import BoostSvg from "../../assets/boost.svg";
import WomanWithBagSvg from "../../assets/womanwithbag.png";
import WomanInMarketSvg from "../../assets/womaninmarket.svg";
import SmilingManSvg from "../../assets/smilingman.svg";

let LandingPage2 = ({ promo }) => {
    let [isSidebarActive, setSidebarActive] = useState(false);

    let [promoActive, setPromoActive] = useState(true);
    let [page, setPage] = useState(0);

    let handleScrolling = async () => {
        console.log("scroll")
        console.log(page)
        if (page != 2) {
            page = setPage(page + 1);
        }



    }
    return <>
        {/*<div style={{backgroundImage: `linear-gradient(to right top, #051937, #002265, #002993, #002ac1, #121ceb)`  }} className="h-screen w-screen">
            <TopNavComponent></TopNavComponent>
        </div>*/}
        <SurveyModal></SurveyModal>
        {(promo == true && promoActive == true) ? <PromoModal setActive={setPromoActive}></PromoModal> : ""}
        {isSidebarActive ? <HomePageSidebarComponent setPage={setPage} setSidebarActive={setSidebarActive}></HomePageSidebarComponent> : ""}
        <div className="h-screen bg-white  w-screen">
            <div className="flex flex-col">
                <div className="md:flex md:flex-col hidden ">
                    <div className="flex flex-col justify-around relative top-8 items-center">
                        <img src={BoostSvg}>

                        </img>
                    </div>
                    <div className="flex flex-row relative -top-10 justify-between items-center w-full px-[100px]">
                        <div className="justify-start flex flex-row p-4 gap-[30px]">
                            <p>Home</p>
                            <p>Features</p>
                            <p>Request a demo</p>
                        </div>
                        <Link to={"/login"}>
                            <div className="justify-end p-4 justify-items-end outline outline-[#5700F8] text-[#5700F8] text-sm px-[50px] py-[16px]">Login</div>
                        </Link>



                    </div>
                </div>
                <div className=" flex flex-row justify-between items-center px-[20px] md:hidden">
                    <div className="">
                        <FontAwesomeIcon icon={faNavicon}></FontAwesomeIcon>
                    </div>
                    <div className="flex flex-col justify-around items-center">
                        <img src={BoostSvg}>

                        </img>
                    </div>
                    <div>
                        <Link to={"/login"}>
                            <div className="justify-end p-4 justify-items-end outline outline-[#5700F8] text-[#5700F8] text-sm px-[20px] py-[16px]">Login</div>
                        </Link>
                    </div>

                </div>
                <div className="md:px-[100px]">
                    <div className="rounded-[13px] bg-[#EEE6FE] px-[40px] md:px-[60px] py-[50px] gap-[1.875rem] flex flex-col md:flex-row justify-between">
                        <div className="flex flex-col gap-4">
                            <div>
                                <p className="md:flex hidden text-[56px] text-left  font-bold">
                                    Build loyal
                                    <br></br>
                                    customers without
                                    <br></br>
                                    stress.

                                </p>
                                <p className="text-3xl md:hidden text-left  font-bold">
                                    Build loyal customers
                                    <br></br>
                                    without stress.

                                </p>
                            </div>
                            <div className="text-xs">
                                <p className="text-left">Boost turns your one-time transactions into lifetime
                                    <br></br>
                                    customers offering features like loyalty rewards, discount
                                    <br></br>
                                    coupons, campaign management and payment links..</p>
                            </div>
                            <div className="flex flex-row gap-2">
                                <Link to={"/onboard"}>
                                    <div className="bg-[#5700F8] text-white text-sm px-[20px] md:px-[50px] py-[16px] rounded-[3px]">Start free trial</div>
                                </Link>
                                <Link to={"/login"}>
                                    <div className="outline outline-[#5700F8] text-[#5700F8] text-sm px-[20px] md:px-[50px] py-[16px]">Login</div>
                                </Link>
                            </div>

                        </div>
                        <div className="">
                            <img src={WomanWithBagSvg}>

                            </img>
                        </div>
                    </div>
                </div>

                <div className="bg-black mt-[35px] flex flex-col md:flex-row gap-4 px-[40px] md:px-[126px] py-[147px] items-center">
                    <div>
                        <p className="text-white font-bold text-2xl md:text-[56px]">
                            You never have to worry about customer attrition,
                            and we mean it..
                        </p>
                    </div>
                    <div>
                        <p className="text-white font-medium md:text-[18px]">
                            Our solution helps you manage your business, track payment, and ensure your customers keep coming back. We believe recurring transactions are your ultimate competitive advantage.Sign up now to see how our technology can set up your business for success.
                        </p>
                    </div>
                </div>

                <div className="px-[40px] md:px-[128px] flex flex-col md:flex-row items-center py-[70px] gap-[87px]">
                    <div className="flex flex-col text-left">
                        <p className="font-bold text-[20px] md:text-[40px] mb-[20px]">
                            Supercharge your
                            <br></br>
                            wellness business
                        </p>

                        <p className="font-regular text-[18px] text-[#5C5C5C] ">
                            Meet customers where they are, boost recurring revenue and keep clients coming back with easy to use payment links and loyalty programs.
                        </p>
                        <img className="md:hidden mt-[40px]" src={WomanInMarketSvg}></img>
                        <div className="flex flex-col md:flex-row mt-[40px] ">
                            <div className="flex flex-col gap-[20px] text-left">
                                <p className="font-semibold text-[20px] text-[#0C111A] ">Monitor your revenue</p>
                                <p className="font-regular text-[16px] text-[#5C5C5C]">
                                    Gain real-time insights into your business's profitability with our comprehensive payment reporting tools. Track, revenue, and net profit to make informed decisions and optimize your operations.
                                </p>
                            </div>
                            <div className="flex flex-col gap-[20px] text-left">
                                <p className="font-semibold text-[20px] text-[#0C111A]">Measure performance</p>
                                <p className="font-regular text-[16px] text-[#5C5C5C]">
                                    Analyze your sales data with detailed revenue performance reports. Identify trends, measure growth, and evaluate the success of different services to drive strategic business improvements.
                                </p>
                            </div>
                        </div>

                    </div>

                    <img className="md:flex hidden" src={WomanInMarketSvg}></img>

                </div>

                <div className="md:px-[128px] px-[40px] flex flex-col md:flex-row items-center py-[70px] gap-[87px]">

                    <img className="md:flex hidden" src={SmilingManSvg}></img>

                    <div className="flex flex-col text-left">
                        <p className="font-bold tex-[20px] md:text-[40px] mb-[20px]">
                            Deliver great service;
                            <br></br>
                            we'll ensure returns.
                        </p>
                        <p className="font-regular text-[18px] text-[#5C5C5C] ">
                            At Boost, we make it easy for your business to keep customers coming back. You focus on giving great service, and our platform will handle loyalty programs, customer engagement, and payments—helping your business grow."
                        </p>

                        <img className="md:hidden mt-[40px]" src={SmilingManSvg}></img>

                        <div className="flex flex-col md:flex-row mt-[40px] ">
                            <div className="flex flex-col gap-[20px] text-left">
                                <p className="font-semibold text-[20px] text-[#0C111A] ">Reach Customers</p>
                                <p className="font-regular text-[16px] text-[#5C5C5C]">
                                    Engage with your customers through targeted loyalty campaigns and discount code. Use our tools to send promotions and special offers, building lasting customer relationships.

                                </p>
                            </div>
                            <div className="flex flex-col gap-[20px] text-left">
                                <p className="font-semibold text-[20px] text-[#0C111A]">Grow with Boost</p>
                                <p className="font-regular text-[16px] text-[#5C5C5C]">
                                    Get overdrafts at competitive offers that will help you scale. Never worry about unnecessary documentation and requirements that can be tiring.
                                </p>
                            </div>
                        </div>

                    </div>



                </div>

                <div className="md:px-[128px] flex flex-col md:flex-row items-center md:py-[70px] px-[40px] gap-[87px]">

                    <div className="px-[30px] py-[50px] flex flex-col gap-[50px] text-left bg-[#EEE6FE] rounded-[8px]">
                        <div className="gap-2">
                            <p className="font-semibold text-[40px]">Reach Out to Us</p>
                            <p className="font-regular text-[18px]">Feel free to reach out to us via any of our social media handles.</p>
                        </div>
                        <div className="flex flex-col gap-[40px]">
                            <div className="flex flex-row gap-[14px] items-center">
                                <FontAwesomeIcon className="text-[#0C111A] items-center" icon={faPhone}></FontAwesomeIcon>
                                <p className="font-regular text-[18px] text-[#5C5C5C]">+234 912 541 8144</p>
                            </div>
                            <div className="flex flex-row gap-[14px] items-center">
                                <FontAwesomeIcon className="text-[#0C111A]" icon={faEnvelope}></FontAwesomeIcon>
                                <Link to={"mailto:hello@rouzo.co"}>
                                    <p className="font-regular text-[18px] text-[#5C5C5C] underline underline-offset-1">hello@rouzo.co</p>
                                </Link>
                            </div>
                            <div className="flex flex-row gap-[14px] items-center">
                                <FontAwesomeIcon className="text-[#0C111A]" icon={faLocation}></FontAwesomeIcon>
                                <p className="font-regular text-[18px] text-[#5C5C5C] ">19 Fola Osibo road, off Adebayo Doherty way, Lekki Phase 1</p>
                            </div>
                        </div>

                    </div>

                    <div className="flex flex-col  gap-[40px]">
                        <div className="flex text-left flex-col gap-[10px]">
                            <p className="font-bold text-black text-[56px]">Contact Us</p>
                            <p className="text-[#5C5C5C] font-regular text-[18px]">
                                Have any question or feedback, feel free to reach
                                out to us. We are always available to help.
                            </p>
                        </div>
                        <div className="flex flex-col  gap-[40px] text-left">
                            <div className="grid grid-cols-2 gap-[20px]">
                                <div className="flex flex-col gap-[10px]">
                                    <p className="font-regular text-[18px]">Full Name</p>
                                    <input className="outline outline-[#E5E7EB] py-[13px] font-regular text-[13px]  rounded-[2px] px-[27px]" type="text" placeholder="Full name"></input>
                                </div>
                                <div className="flex flex-col gap-[10px]">
                                    <p className="font-regular text-[18px]">Email Address</p>
                                    <input className="outline outline-[#E5E7EB] py-[13px] font-regular text-[13px]  rounded-[2px] px-[27px]" type="text" placeholder="Email address"></input>
                                </div>

                            </div>
                            <div>
                                <div className="flex flex-col gap-[10px]">
                                    <p className="font-regular text-[18px]">Message</p>
                                    <input className="outline outline-[#E5E7EB] py-[13px]  px-[27px] font-regular text-[13px] rounded-[2px]" type="text" placeholder="Your message here"></input>
                                </div>
                            </div>
                            <div className="flex flex-row">
                                <div className="bg-[#5700F8] text-white text-sm px-[50px] py-[16px] rounded-[3px]">Send message</div>
                            </div>

                        </div>


                    </div>




                </div>

                <div className="md:flex hidden bg-[#EEE6FE] px-[150px] py-[60px] flex flex-col gap-[48px]">
                    <div className=" flex flex-row">
                        <img src={BoostSvg}>

                        </img>
                    </div>
                    <div className="font-medium text-[16px] flex flex-row gap-[40px]">
                        <p>Company</p>
                        <p>Loyalty</p>
                        <p>Payment</p>
                        <p>Overdraft</p>
                        <p>Contact Us</p>
                    </div>
                    <div className="flex font-semibold text-[16px] flex-row justify-between">
                        <p>© {new Date().getFullYear()}. All rights reserved. </p>
                        <div className="flex flex-row gap-[40px]">
                            <p>Terms</p>
                            <p>Privacy</p>
                            <p>Cookies</p>
                        </div>
                    </div>


                </div>
                <div className="md:hidden bg-[#EEE6FE] px-[40px] py-[60px] flex flex-col gap-[48px]">
                    <div className=" flex flex-row">
                        <img src={BoostSvg}>

                        </img>
                    </div>
                    <div className="flex-row gap-4 text-left flex">
                        <div className="font-medium text-[16px] flex flex-col gap-[40px]">
                            <p>Company</p>
                            <p>Loyalty</p>
                            <p>Payment</p>
                            <p>Overdraft</p>
                            <p>Contact Us</p>
                        </div>

                        <div className="flex font-medium  flex-col gap-[40px]">
                            <p>Terms</p>
                            <p>Privacy</p>
                            <p>Cookies</p>
                        </div>
                    </div>

                    <div className="flex font-semibold text-[16px] flex-row justify-between">
                        <p>© {new Date().getFullYear()}. All rights reserved. </p>

                    </div>


                </div>

            </div>
        </div>
    </>
}



export default LandingPage2;